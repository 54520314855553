import { Article, Solution, Category, Tag, ImageUrls } from '../../client-content/index';
import { FormGroup } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

export interface DisplayArticle extends Article {
    mainImageBackgroundImageStyle: any;
}

export class DisplaySolution implements Solution {


    id?: string;

    lastUpdate?: Date;

    creationDate?: Date;

    title?: string;

    excerpt?: string;

    slug?: string;

    content?: string;

    categories?: Array<Category>;

    tags?: Array<Tag>;

    images?: ImageUrls;

    solutionwareId?: string;

    mainImageBackgroundImageStyle: any;

    isComingSoon: boolean;

    constructor(solution?: Solution) {
        if (solution) {
            for (let attribute in solution) {
                //@ts-ignore
                this[attribute] = solution[attribute];
            }

            if (this.categories) {
                for (let i = 0; i < this.categories.length; i++) {
                    if (this.isComingSoon = this.categories[i].slug == 'coming-soon')
                        break;
                }
            }
        }
    }

    public setBackgroundImage(domSanitizer: DomSanitizer, imageSize: any) {
        if (this.images) {
            //@ts-ignore
            let image = this.images[imageSize] ? this.images[imageSize] : this.images.full;
            this.mainImageBackgroundImageStyle = domSanitizer.bypassSecurityTrustStyle('background-image: url(' + image + ')');
        }    
    }
}

export class WeFormGroup extends FormGroup {
    public sent: boolean = false;
    public loading: boolean = false;
}