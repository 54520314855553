import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

type LocalizedUrl = {
  locale: string
  url: string
}

@Component({
  selector: 'localized-url',
  templateUrl: 'localized-url.html'
})
export class LocalizedUrlComponent implements OnChanges {
  @Input("jsonString")
  jsonString: string;

  urls: LocalizedUrl[] = [];

  constructor() {
    this.urls = this.parseJsonString(this.jsonString)
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.urls = this.parseJsonString(this.jsonString)
  }

  parseJsonString(jsonString: string): LocalizedUrl[] {
    const urls = [] as LocalizedUrl[]

    if (jsonString && (jsonString.startsWith('http://') || jsonString.startsWith('https://'))) {
      urls.push({ locale: '', url: jsonString })
      return urls
    }

    try {
      const urlMap = JSON.parse(jsonString)
      for (const key in urlMap) {
        const val = urlMap[key]
        if (typeof key === 'string' && typeof val === 'string') {
          urls.push({ locale: key, url: val })
        }
      }
    } catch (error) { }
    return urls
  }
}
