<header
    class="empty"
    style="background-image: url(./frontend/img/header-bg.jpg)"
></header>
<div class="container" [ngClass]="{'loading white':loading}">
    <div class="box" *ngIf="user">
        <h1>{{user?.name}}</h1>
        <table class="table">
            <tbody>
                <tr>
                    <td>
                        <strong translate>USER_SALUTATION</strong>
                    </td>
                    <td>{{'SALUTATION_' + user.salutation|translate}}</td>
                </tr>
                <tr>
                    <td>
                        <strong translate>USER_EMAIL</strong>
                    </td>
                    <td>{{user.name}}</td>
                </tr>
                <tr>
                    <td>
                        <strong translate>USER_NAME</strong>
                    </td>
                    <td>{{user.firstName}} {{user.lastName}}</td>
                </tr>
                <tr>
                    <td>
                        <strong translate>USER_TELEPHONE</strong>
                    </td>
                    <td>{{user.telefon}}</td>
                </tr>
                <tr>
                    <td>
                        <strong translate>USER_MOBILE</strong>
                    </td>
                    <td>{{user.mobile}}</td>
                </tr>
                <tr>
                    <td>
                        <strong translate>USER_RECEIVE_NEWSLETTER</strong>
                    </td>
                    <td>
                        <i
                            class="fa"
                            [ngClass]="{'fa-check':user.receiveNewsletter, 'fa-times':!user.receiveNewsletter}"
                        ></i>
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong translate>USER_STATUS</strong>
                    </td>
                    <td>{{user.status}}</td>
                </tr>
                <tr>
                    <td>
                        <strong translate>USER_ACCEPT_INVITE_LINK</strong>
                    </td>
                    <td>
                        <a
                            *ngIf="user.status == 'PENDING' && acceptInviteLink"
                            href="{{acceptInviteLink}}"
                            >{{acceptInviteLink}}</a
                        >
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong translate>USER_CREATION_DATE</strong>
                    </td>
                    <td>{{user.creationDate | date: 'dd.MM.yy HH:mm'}}</td>
                </tr>
                <tr>
                    <td>
                        <strong translate>USER_LAST_UPDATE</strong>
                    </td>
                    <td>{{user.lastUpdate | date: 'dd.MM.yy HH:mm'}}</td>
                </tr>
                <tr>
                    <td>
                        <strong translate>USER_MASTER_BUSINESS</strong>
                    </td>
                    <td>
                        <a
                            *ngIf="masterBusiness"
                            routerLink="/business/{{masterBusiness.id}}"
                            >{{masterBusiness.name}}</a
                        >
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong translate>USER_BUSINESS</strong>
                    </td>
                    <td>
                        <a routerLink="/business/{{user.business.id}}"
                            >{{user.business.name}}</a
                        >
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong translate>USER_ROLES</strong>
                    </td>
                    <td>
                        <div *ngFor="let businessRole of user.businessRoles">
                            {{'USER_ROLE_' + businessRole.role |translate}}
                            <a
                                routerLink="/business/{{businessRole.business.id}}"
                                >{{businessRole.business.name}}</a
                            >
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong translate>USER_BUSINESS_UNIT</strong>
                    </td>
                    <td>{{user.businessUnit}}</td>
                </tr>
                <tr>
                    <td>
                        <strong translate>USER_LOCALE</strong>
                    </td>
                    <td>{{user.locale}}</td>
                </tr>
                <tr>
                    <td><strong translate> LOGIN_LOCK_TITLE </strong></td>
                    <td *ngIf="!isLoginLocked()" translate>
                        {{attempts()}}
                    </td>
                    <td
                        *ngIf="isLoginLocked()"
                        translate
                        [translateParams]="{ attempts: attempts(), endDate: lockEnddate() |  date: 'dd.MM.yy HH:mm'}"
                    >
                        {{attempts()}} | Letzter Versuch: {{lockEnddate() |  date: 'dd.MM.yy HH:mm'}}
                    </td>
                </tr>
            </tbody>
        </table>
        <p>
            <a (click)="showRaw = !showRaw" class="btn btn-default" translate
                >USER_SHOW_RAW</a
            >
            <a
                *ngIf="user.status == 'PENDING'"
                (click)="resendInvite()"
                class="btn btn-info"
                translate
                >USER_RESEND_INVITE</a
            >
        </p>
        <pre *ngIf="showRaw">{{user|json}}</pre>
        <p>
            <a (click)="goBack()" class="btn btn-primary" translate
                >BUTTON_BACK</a
            >&nbsp;
            <a
                *ngIf="enableTokenLogin"
                routerLink="/token-login/{{user.id}}"
                class="btn btn-default"
                translate
                >LOGIN</a
            >&nbsp;
            <a
                *ngIf="isLoginLocked()"
                (click)="removeLock()"
                class="btn btn-primary"
                translate
                >LOGIN_LOCK_REMOVE</a
            >&nbsp;
            <a
                *ngIf="enableCrmUpdate"
                (click)="crmUpdate()"
                class="btn btn-danger pull-right"
                translate
                >USER_CRM_UPDATE_BUTTON</a
            >
        </p>
    </div>
</div>
