import 'core-js/es'
import 'core-js/es/reflect'
require('zone.js/dist/zone')
if (process.env.ENV === 'production') {
    // Production
} else {
    // Development
    Error['stackTraceLimit'] = Infinity
    require('zone.js/dist/long-stack-trace-zone')
}
