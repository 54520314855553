/**
 * Contentware
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: .
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Segment = 'NONE' | 'TOP_BANNER' | 'LEVEL_1' | 'LEVEL_2' | 'LEVEL_3';

export const Segment = {
    NONE: 'NONE' as Segment,
    TOP_BANNER: 'TOP_BANNER' as Segment,
    LEVEL_1: 'LEVEL_1' as Segment,
    LEVEL_2: 'LEVEL_2' as Segment,
    LEVEL_3: 'LEVEL_3' as Segment
};

