import { Component, OnInit } from '@angular/core'
import { SessionService } from '../services/session.service'
import { SystemRole } from '../../../client-user/index'
import { Campaign, CampaignsService, Segment } from '../../../client-content/index'
import { sortObjectArrByProps } from '@nighly/sort-object-array-by-property'

@Component({
    selector: 'campaign',
    templateUrl: 'campaigns.html',
})
export class CampaignsComponent implements OnInit {
    lastKeyForSort: string = ''
    listIsReversed: boolean = false
    campaigns: Campaign[]
    query: string
    segment: Segment
    active: boolean
    from: Date
    to: Date
    segments: Segment[] = [
        Segment.NONE,
        Segment.TOP_BANNER,
        Segment.LEVEL_1,
        Segment.LEVEL_2,
        Segment.LEVEL_3,
    ]

    constructor(
        public sessionService: SessionService,
        private campaignsService: CampaignsService
    ) {
        sessionService.onHasRole(SystemRole.CONTENT_ADMIN, () => {
            this.updateCampaigns()
        })
    }

    public updateCampaigns() {
        let from: string = this.from ? this.from.toDateString() : null
        let to: string = this.from ? this.to.toDateString() : null
        this.campaignsService
            .listCampaigns(
                this.sessionService.getSessionId(),
                this.query,
                this.segment,
                this.active,
                from,
                to
            )
            .subscribe((list) => {
                this.campaigns = list
            })
    }

    sortList(paramKey: string) {
        let response: Campaign[] = []
        if (this.lastKeyForSort === paramKey && !this.listIsReversed) {
            response = sortObjectArrByProps(this.campaigns, paramKey, 'r')
            this.listIsReversed = true
        } else {
            response = sortObjectArrByProps(this.campaigns, paramKey)
            this.listIsReversed = false
        }
        this.lastKeyForSort = paramKey
        this.campaigns = response
    }

    ngOnInit() {}
}
