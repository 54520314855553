import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import {
    AdminService,
    Article,
    ArticlesService,
    ContentConfig,
    Solution,
    SolutionService,
} from '../../../client-content'
import { SystemRole } from '../../../client-user/index'
import { SessionService } from '../services/session.service'
import { sortObjectArrByProps } from '@nighly/sort-object-array-by-property'

@Component({
    selector: 'homepage-settings',
    templateUrl: 'homepage-settings.html',
})
export class HomepageSettings implements OnInit {
    lastArtcileKeyForSort: string = ''
    articleListIsReversed: boolean = false
    lastSolutionKeyForSort: string = ''
    solutionListIsReversed: boolean = false
    contentConfig: ContentConfig
    solutionsPage: number = 1
    articlesPage: number = 1
    solutions: Solution[]
    articles: Article[]
    LIMIT = 5

    constructor(
        public sessionService: SessionService,
        private articlesService: ArticlesService,
        private solutionService: SolutionService,
        private route: ActivatedRoute,
        private adminService: AdminService
    ) {
        sessionService.onHasRole(SystemRole.USER_ADMIN, () => {
            this.route.params.subscribe((params) => {
                let pa = params['articlesPage']
                let ps = params['solutionsPage']
                if (pa && pa > 0) {
                    this.articlesPage = parseInt(pa)
                }
                if (ps && ps > 0) {
                    this.solutionsPage = parseInt(ps)
                }
                articlesService
                    .listArticles(
                        'de_de',
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        (this.articlesPage - 1) * this.LIMIT,
                        this.LIMIT
                    )
                    .subscribe((result) => {
                        this.articles = result
                    })
                solutionService
                    .listSolutions(
                        'de_de',
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        (this.solutionsPage - 1) * this.LIMIT,
                        this.LIMIT
                    )
                    .subscribe((result) => {
                        this.solutions = result
                    })
                adminService
                    .getConfig(sessionService.getSessionId())
                    .subscribe((result) => {
                        this.contentConfig = result
                    })
            })
        })
    }

    sortList(theme: string, paramKey: string) {
        if (theme === 'article') {
            this.sortArticle(paramKey)
        } else {
            this.sortSolution(paramKey)
        }
    }

    private sortArticle(key: string) {
        let response: Article[] = []
        if (this.lastArtcileKeyForSort === key && !this.articleListIsReversed) {
            response = sortObjectArrByProps(this.articles, key, 'r')
            this.articleListIsReversed = true
        } else {
            response = sortObjectArrByProps(this.articles, key)
            this.articleListIsReversed = false
        }
        this.lastArtcileKeyForSort = key
        this.articles = response
    }

    private sortSolution(key: string) {
        let response: Solution[] = []
        if (
            this.lastSolutionKeyForSort === key &&
            !this.solutionListIsReversed
        ) {
            response = sortObjectArrByProps(this.solutions, key, 'r')
            this.solutionListIsReversed = true
        } else {
            response = sortObjectArrByProps(this.solutions, key)
            this.solutionListIsReversed = false
        }
        this.lastSolutionKeyForSort = key
        this.solutions = response
    }

    setFeaturedArticle(article: Article) {
        this.contentConfig.featuredArticle = article
        this.adminService
            .updateConfig(
                this.sessionService.getSessionId(),
                this.contentConfig
            )
            .subscribe()
        this.articlesService
            .listArticles(
                'de_de',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                (this.articlesPage - 1) * this.LIMIT,
                this.LIMIT
            )
            .subscribe((result) => {
                this.articles = result
            })
    }

    setFeaturedSolution(solution: Solution) {
        this.contentConfig.featuredSolution = solution
        this.adminService
            .updateConfig(
                this.sessionService.getSessionId(),
                this.contentConfig
            )
            .subscribe()
        this.solutionService
            .listSolutions(
                'de_de',
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                (this.solutionsPage - 1) * this.LIMIT,
                this.LIMIT
            )
            .subscribe((result) => {
                this.solutions = result
            })
    }

    ngOnInit() {}
}
