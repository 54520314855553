import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import 'jquery';
import { UserService, SessionService as UserwareSessionService } from '../../../client-user/index';
import { WeFormGroup } from '../models';
import { FormControl, Validators } from '@angular/forms';
import { ErrorService, FormErrorValue } from '../services/error-handle.service';
import { SessionService } from '../services/session.service';

@Component({
    selector: 'user-login',
    templateUrl: 'user-login.html',
})
export class UserLoginComponent implements OnInit {
    loginForm: WeFormGroup;
    formError: FormErrorValue = new FormErrorValue();
    ready: boolean = false;
    constructor(
        private errorService: ErrorService,
        private router: Router,
        public sessionService: SessionService,
        private userwareSessionService: UserwareSessionService,
        private userService: UserService,
    ) {
        sessionService.onReady(() => {
            if (this.sessionService.isLoggedIn()) {
                this.router.navigate(['']);
            }
            this.ready = true;
        });
        let controls: {} = {
            username: new FormControl('', Validators.required),
            password: new FormControl('', Validators.required),
        };
        this.loginForm = new WeFormGroup(controls);
    }

    ngOnInit() {}

    submitForm() {
        this.loginForm.sent = true;
        if (this.loginForm.valid) {
            let username: string = this.loginForm.get('username').value;
            let password: string = this.loginForm.get('password').value;
            this.loginForm.loading = true;
            const buildErrorHandler = () => {
                return this.errorService.buildFormHandler(this.loginForm, this.formError, {
                    statusHandles: { 403: { control: 'username', error: 'invalid' } },
                });
            };
            this.userwareSessionService.loginAndGetUserwareSession(username, password, true).subscribe({
                next: (session) => {
                    this.userService.getUserwareUser(session.userId, session.sessionId).subscribe({
                        next: (user) => {
                            this.sessionService.setSession(session, user);
                            this.router.navigate(['/users']);
                        },
                        error: buildErrorHandler(),
                    });
                },
                error: buildErrorHandler(),
            });
        }
    }
}
