/**
 * Solutionware
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: .
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SolutionFieldType = 'STRING' | 'LONG_STRING' | 'NUMBER' | 'BOOLEAN' | 'DATE' | 'CHOICE';

export const SolutionFieldType = {
    STRING: 'STRING' as SolutionFieldType,
    LONG_STRING: 'LONG_STRING' as SolutionFieldType,
    NUMBER: 'NUMBER' as SolutionFieldType,
    BOOLEAN: 'BOOLEAN' as SolutionFieldType,
    DATE: 'DATE' as SolutionFieldType,
    CHOICE: 'CHOICE' as SolutionFieldType
};

