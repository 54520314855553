<header
    class="empty"
    style="background-image: url(./frontend/img/header-bg.jpg)"
></header>
<div class="container">
    <div class="box">
        <h1>Kampagne verwalten</h1>
        <form
            class="form-horizontal"
            *ngIf="form"
            (ngSubmit)="submitForm()"
            [formGroup]="form"
            [ngClass]="{loading:form?.loading}"
        >
            <div class="row">
                <div class="col-sm-12">
                    <input-control
                        name="name"
                        label="Name *"
                        [form]="form"
                        type="text"
                    ></input-control>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <input-control
                        name="validFrom"
                        label="Start *"
                        [form]="form"
                        type="date"
                    ></input-control>
                </div>
                <div class="col-sm-3">
                    <input-control
                        name="validTo"
                        label="Ende *"
                        [form]="form"
                        type="date"
                    ></input-control>
                </div>
                <div class="col-sm-3">
                    <select-control
                        name="segment"
                        label="Segment *"
                        [form]="form"
                        [options]="segments"
                    ></select-control>
                </div>
                <div class="col-sm-3">
                    <select-control
                        name="priority"
                        label="Priorität *"
                        [form]="form"
                        [options]="priorities"
                    ></select-control>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <input-control
                        name="active"
                        label="Aktiv"
                        [form]="form"
                        type="checkbox"
                    ></input-control>
                </div>
            </div>
        </form>
        <div class="row">
            <div class="col-sm-6">
                <h4>Artikel</h4>
                <div class="campaign-article-container">
                    <a
                        class="campaign-article"
                        *ngFor="let a of articles; index as i"
                        (click)="removeArticle(i)"
                    >
                        <span [innerHtml]="a.title"></span
                        ><i class="fa fa-minus-circle"></i>
                    </a>
                    <div class="alert alert-warning" *ngIf="!articles?.length">
                        Dieser Kampagne sind noch keine Artikel zugeordnet.
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="input-group">
                    <input
                        type="search"
                        class="form-control"
                        [(ngModel)]="search"
                        (keyup)="doSearch()"
                    />
                    <span class="input-group-addon"
                        ><i class="fa fa-search"></i
                    ></span>
                </div>
                <div
                    class="campaign-article-container"
                    [ngClass]="{loading:searchLoading}"
                >
                    <a
                        class="campaign-article"
                        *ngFor="let a of searchArticles; index as i"
                        (click)="addArticle(i)"
                    >
                        <span [innerHtml]="a.title"></span
                        ><i class="fa fa-plus-circle"></i>
                    </a>
                    <div
                        class="alert alert-info"
                        *ngIf="!searchArticles?.length"
                    >
                        Es wurden keine Artikel zu der Suche gefunden.
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <div
                    class="alert alert-danger"
                    *ngIf="!articles?.length && form?.sent"
                >
                    Es muss mindestens ein Artikel ausgewählt werden!
                </div>
                <form-error [error]="formError"></form-error>
                <button
                    type="submit"
                    (click)="submitForm()"
                    class="btn btn-primary"
                >
                    Speichern
                </button>
                <a routerLink="/campaign" class="btn btn-warning">Zurück</a>
                <a (click)="openDelete = true" class="btn btn-danger"
                    >Löschen</a
                >
                <div class="pull-right">* Pflichtfeld</div>
            </div>
        </div>
    </div>
</div>
<ng-container *ngIf="openDelete">
    <div class="modal modal-show" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button
                        type="button"
                        class="close"
                        (click)="openDelete = false"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 class="modal-title">Löschen bestätigen</h4>
                </div>
                <div class="modal-body">
                    <p>Wollen Sie diese Kampagne wirklich löschen?</p>
                </div>
                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-default"
                        (click)="openDelete = false"
                    >
                        Nein
                    </button>
                    <button
                        type="button"
                        class="btn btn-primary"
                        (click)="doDelete()"
                    >
                        Ja
                    </button>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
    <div class="modal-backdrop in"></div>
</ng-container>
