<header
    class="empty"
    style="background-image: url(./frontend/img/header-bg.jpg)"
></header>
<div class="container">
    <div class="box" [ngClass]="{'loading':loading}">
        <h1>
            Lösung bearbeiten
            <a routerLink="/solutions" class="btn btn-default pull-right"
                >Zurück</a
            >
        </h1>
        <ng-template [ngIf]="definition">
            <div class="row">
                <div class="col-md-12">Name: {{definition.name}}</div>
            </div>
            <div class="row">
                <div class="col-md-12">Beschreibung: {{definition.label}}</div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    Letze Änderung: {{definition.lastUpdate | date:'dd.MM.yy
                    HH:mm'}}
                </div>
                <div class="col-md-6">
                    Erstellt: {{definition.creationDate | date:'dd.MM.yy
                    HH:mm'}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    Partner: {{definition.partner?.name}}
                </div>
            </div>
            <div class="form-group">
                <h3>Felder</h3>
                <table class="table">
                    <thead>
                        <tr>
                            <th>Name *</th>
                            <th>Beschriftung *</th>
                            <th>Platzhaltertext</th>
                            <th>Beschreibung</th>
                            <th>Werte</th>
                            <th>Typ</th>
                            <th>Spalte</th>
                            <th>Pflicht</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container
                            *ngFor="let field of definition?.fieldDefinitions; index as i;"
                        >
                            <tr>
                                <td>
                                    <input
                                        type="text"
                                        class="form-control input-sm"
                                        name="name"
                                        [(ngModel)]="field.name"
                                        placeholder="Name *"
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        class="form-control input-sm"
                                        name="label"
                                        [(ngModel)]="field.label"
                                        placeholder="Beschriftung *"
                                    />
                                </td>
                                <td>
                                    <input
                                        *ngIf="field.fieldType == 'STRING' || field.fieldType == 'NUMBER' || field.fieldType == 'LONG_STRING'"
                                        type="text"
                                        class="form-control input-sm"
                                        name="placeholder"
                                        [(ngModel)]="field.placeholder"
                                        placeholder="Platzhalter"
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        class="form-control input-sm"
                                        name="description"
                                        [(ngModel)]="field.description"
                                        placeholder="Beschreibung"
                                    />
                                </td>
                                <td>
                                    <ng-template
                                        [ngIf]="field.fieldType == 'CHOICE'"
                                    >
                                        <array-control
                                            [(values)]="field.values"
                                            [class]="'form-control input-sm'"
                                        ></array-control>
                                        <div class="checkbox input-sm">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name="multiple"
                                                    [(ngModel)]="field.multiple"
                                                />
                                                <i class="fa fa-square-o"></i>
                                                <i
                                                    class="fa fa-check-square"
                                                ></i>
                                                Mehrfachauswahl
                                            </label>
                                        </div>
                                    </ng-template>
                                </td>
                                <td>
                                    <select
                                        name="fieldType"
                                        [(ngModel)]="field.fieldType"
                                        class="form-control input-sm"
                                    >
                                        <option
                                            value="{{f.value}}"
                                            *ngFor="let f of fieldTypes"
                                        >
                                            {{f.label}}
                                        </option>
                                    </select>
                                </td>
                                <td>
                                    <select
                                        name="column"
                                        [(ngModel)]="field.column"
                                        class="form-control input-sm"
                                    >
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                    </select>
                                </td>
                                <td>
                                    <div class="checkbox input-sm">
                                        <label>
                                            <input
                                                type="checkbox"
                                                name="required"
                                                [ngModel]="constraintIs(i,'REQUIRED')"
                                                (ngModelChange)="setConstraint($event, i, 'REQUIRED')"
                                            />
                                            <i class="fa fa-square-o"></i>
                                            <i class="fa fa-check-square"></i>
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <a
                                        (click)="removeField(i)"
                                        class="btn btn-danger btn-sm pull-right"
                                    >
                                        <i class="fa fa-trash"></i>
                                    </a>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="8">
                                <a
                                    (click)="addField()"
                                    class="btn btn-info btn-sm"
                                >
                                    <i class="fa fa-plus"></i> Feld
                                    hinzufügen</a
                                >
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <form-error [error]="formError"></form-error>
                    <button
                        type="submit"
                        (click)="submitForm()"
                        class="btn btn-primary"
                    >
                        Speichern
                    </button>
                    <a routerLink="/solutions" class="btn btn-default"
                        >Abbrechen</a
                    >
                    <div class="pull-right">* Pflichtfeld</div>
                </div>
            </div>
        </ng-template>
    </div>
</div>
<ng-container *ngIf="openDelete">
    <div class="modal modal-show" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button
                        type="button"
                        class="close"
                        (click)="openDelete = false"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 class="modal-title">Löschen bestätigen</h4>
                </div>
                <div class="modal-body">
                    <p>Wollen Sie diese Lösung wirklich löschen?</p>
                </div>
                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-default"
                        (click)="openDelete = false"
                    >
                        Nein
                    </button>
                    <button
                        type="button"
                        class="btn btn-primary"
                        (click)="doDelete()"
                    >
                        Ja
                    </button>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
    <div class="modal-backdrop in"></div>
</ng-container>
