import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { SessionService } from '../services/session.service'
import { SystemRole } from '../../../client-user/index'
import {
    Campaign,
    CampaignsService,
    Article,
    ArticlesService,
    Segment,
} from '../../../client-content/index'
import { WeFormGroup } from '../models'
import { FormControl, Validators } from '@angular/forms'
import { ErrorService, FormErrorValue } from '../services/error-handle.service'
import { FlashService } from '../flash/flash.service'
import { LangService } from '../services/lang.service'
import moment from 'moment'

@Component({
    selector: 'campaign-detail',
    templateUrl: 'campaign-detail.html',
})
export class CampaignDetailComponent implements OnInit {
    campaign: Campaign
    form: WeFormGroup
    formError: FormErrorValue = new FormErrorValue()
    isCreate: boolean = true
    articles: Article[] = []
    searchArticles: Article[] = []
    search: string
    timer: number
    searchLoading: boolean = false
    openDelete: boolean = false
    segments: { label: string; value: Segment }[] = [
        {
            label: 'Keins',
            value: Segment.NONE,
        },
        {
            label: 'Top Banner',
            value: Segment.TOP_BANNER,
        },
        {
            label: 'Level 1',
            value: Segment.LEVEL_1,
        },
        {
            label: 'Level 2',
            value: Segment.LEVEL_2,
        },
        {
            label: 'Level 3',
            value: Segment.LEVEL_3,
        },
    ]
    priorities: { label: number; value: number }[] = []

    constructor(
        public sessionService: SessionService,
        private campaignsService: CampaignsService,
        private articlesService: ArticlesService,
        private route: ActivatedRoute,
        private router: Router,
        private errorService: ErrorService,
        private flashService: FlashService,
        private langService: LangService
    ) {
        for (var i = 0; i < 11; i++) {
            this.priorities.push({ label: i, value: i })
        }
        sessionService.onHasRole(SystemRole.CONTENT_ADMIN, () => {
            this.route.params.subscribe((params) => {
                let id = params['id']
                if (id) {
                    this.isCreate = false
                    this.campaignsService
                        .getCampaign(id, this.sessionService.getSessionId())
                        .subscribe(
                            (c) => {
                                this.campaign = c
                                this.initForm()
                            },
                            this.errorService.buildSimpleHandler({
                                statusHandles: {
                                    default: (code: any) => {
                                        this.router.navigate(['/error/' + code])
                                    },
                                },
                            })
                        )
                } else {
                    this.campaign = {
                        name: '',
                        articleIds: [],
                        segment: Segment.NONE,
                        validFrom: new Date(),
                        validTo: new Date(
                            new Date().getTime() + 24 * 60 * 60 * 1000
                        ),
                    }
                    this.initForm()
                }
            })
        })
    }

    private initForm() {
        let controls: {} = {
            name: new FormControl(this.campaign.name, Validators.required),
            segment: new FormControl(
                this.campaign.segment,
                Validators.required
            ),
            validFrom: new FormControl(
                moment(this.campaign.validFrom).format('YYYY-MM-DD'),
                Validators.required
            ),
            validTo: new FormControl(
                moment(this.campaign.validTo).format('YYYY-MM-DD'),
                Validators.required
            ),
            priority: new FormControl(
                this.campaign.priority,
                Validators.required
            ),
            active: new FormControl(this.campaign.active),
        }
        this.form = new WeFormGroup(controls)
        this.articlesService
            .listArticles(this.langService.current)
            .subscribe((list) => {
                this.searchArticles = list
            })
        this.articlesService
            .listArticlesForIds(this.campaign.articleIds)
            .subscribe((list) => {
                this.articles = list
            })
    }

    public submitForm() {
        this.form.sent = true
        if (this.form.valid && this.articles.length) {
            this.form.loading = true
            this.campaign = { ...this.campaign, ...this.form.value }
            this.campaign.articleIds = []
            this.articles.forEach((element) => {
                this.campaign.articleIds.push(element.id)
            })
            if (this.isCreate) {
                this.campaignsService
                    .createCampaign(
                        this.sessionService.getSessionId(),
                        this.campaign
                    )
                    .subscribe((newCampaign) => {
                        this.flashService.addMessage(
                            'Neue Kampagne erstellt!',
                            'success'
                        )
                        this.router.navigate(['/campaign/' + newCampaign.id])
                        this.form.loading = false
                    }, this.errorService.buildFormHandler(this.form, this.formError))
            } else {
                this.campaignsService
                    .updateCampaign(
                        this.campaign.id,
                        this.sessionService.getSessionId(),
                        this.campaign
                    )
                    .subscribe((newCampaign) => {
                        //this.router.navigate(['/campaign/' + newCampaign.id]);
                        this.form.loading = false
                        this.flashService.addMessage(
                            'Änderungen wurden gespeichert!',
                            'success'
                        )
                    }, this.errorService.buildFormHandler(this.form, this.formError))
            }
        }
    }

    public addArticle(index: number) {
        let a = this.searchArticles[index]
        if (a) {
            let i = this.articles.findIndex((cur, i, o) => {
                return cur.id == a.id
            })
            if (i < 0) {
                this.articles.push(a)
            }
        }
    }

    public removeArticle(index: number) {
        this.articles.splice(index, 1)
    }

    public doSearch() {
        if (this.timer) {
            window.clearTimeout(this.timer)
        }
        this.timer = window.setTimeout(() => {
            this.searchLoading = true
            this.articlesService
                .listArticles(this.langService.current, this.search)
                .subscribe(
                    (list) => {
                        this.searchArticles = list
                        this.searchLoading = false
                    },
                    this.errorService.buildFormHandler(
                        this.form,
                        this.formError,
                        {
                            always: () => {
                                this.searchLoading = false
                            },
                        }
                    )
                )
        }, 200)
    }

    public doDelete() {
        this.campaignsService
            .deleteCampaign(
                this.campaign.id,
                this.sessionService.getSessionId()
            )
            .subscribe(
                (data) => {
                    this.flashService.addMessage(
                        'Die Kampagne wurde erfolgreich gelöscht.',
                        'success'
                    )
                    this.router.navigate(['/campaign'])
                },
                this.errorService.buildFormHandler(this.form, this.formError, {
                    always: () => {
                        this.openDelete = false
                    },
                })
            )
    }

    ngOnInit() {}
}
