import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionService } from "../services/session.service";
import { WeFormGroup } from '../models';
import { SystemRole } from '../../../client-user/index';
import { AdvertisementPackage, AdvertisementPackageService, AdvertisementStatus, AdType } from '../../../client-content/index';
import { FormControl, Validators } from '@angular/forms';
import { ErrorService, FormErrorValue } from '../services/error-handle.service';
import { FlashService } from "../flash/flash.service";

@Component({
    selector: 'ad-package-detail',
    templateUrl: 'ad-package-detail.html'
})

export class AdPackageDetailComponent implements OnInit {
    form: WeFormGroup;
    formError: FormErrorValue = new FormErrorValue();
    package: AdvertisementPackage;
    isCreate: boolean = true;
    openDelete: boolean = false;
    statuses: { label: string, value: AdvertisementStatus }[] =
    [{
        label: "" + AdvertisementStatus.RESERVED, value: AdvertisementStatus.RESERVED
    },
    {
        label: "" + AdvertisementStatus.BOOKED, value: AdvertisementStatus.BOOKED
    },
    {
        label: "" + AdvertisementStatus.CANCELED, value: AdvertisementStatus.CANCELED
    },
    {
        label: "" + AdvertisementStatus.EXPIRED, value: AdvertisementStatus.EXPIRED
    },
    ];

    constructor(public sessionService: SessionService, public advertisementPackageService: AdvertisementPackageService, private route: ActivatedRoute, private router: Router, private errorService: ErrorService, private flashService: FlashService) {

        sessionService.onHasRole(SystemRole.CONTENT_ADMIN, () => {
            this.route.params.subscribe(params => {
                let id = params['id'];
                if (id) {
                    this.isCreate = false;
                    this.advertisementPackageService.getAdvertisementPackage(id, this.sessionService.getSessionId()).subscribe(data => {
                        this.package = data;
                        this.initForm();
                    }, this.errorService.buildSimpleHandler({
                        statusHandles: {
                            default: (code: any) => {
                                this.router.navigate(['/error/' + code])
                            }
                        }
                    }));
                } else {
                    this.package = {
                        name: '',
                        notes: '',
                        status: AdvertisementStatus.RESERVED,
                        reservedUntil: null
                    };
                    this.initForm();
                }
            })
        })
    }

    private initForm() {
        let controls: {} = {
            'name': new FormControl(this.package.name, Validators.required),
            'notes': new FormControl(this.package.notes),
            'status': new FormControl(this.package.status),
            'reservedUntil': new FormControl(this.package.reservedUntil, Validators.required)
        };
        this.form = new WeFormGroup(controls);
    }

    public removeAdvertisement(i: number) {
        if (this.package) {
            this.package.advertisements.splice(i, 1);
        }
    }

    public addAdvertisement() {
        if (this.package) {
            if (!this.package.advertisements) {
                this.package.advertisements = [];
            }
            this.package.advertisements.push({
                startDate: new Date(),
                endDate: new Date(),
                type: AdType.BANNER_START_LEVEL_1,
                notes: '',
                status: AdvertisementStatus.RESERVED
            });
        }
    }

    public submitForm() {
        this.form.sent = true;
        if (this.form.valid) {
            this.form.loading = true;
            this.package = { ...this.package, ...this.form.value };
            if (this.isCreate) {
                this.advertisementPackageService.createAdvertisementPackage(this.sessionService.getSessionId(), this.package).subscribe((newPackage) => {
                    this.flashService.addMessage("Neue Buchung erstellt!", "success");
                    this.router.navigate(['/ad-package/' + newPackage.id]);
                    this.form.loading = false;
                }, this.errorService.buildFormHandler(this.form, this.formError));
            } else {
                this.advertisementPackageService.updateAdvertisementPackage(this.package.id, this.sessionService.getSessionId(), this.package).subscribe((newFlow) => {
                    //this.router.navigate(['/definition/' + newFlow.id]);
                    this.form.loading = false;
                    this.flashService.addMessage("Änderungen wurden gespeichert!", "success");
                }, this.errorService.buildFormHandler(this.form, this.formError));
            }
        }
    }

    public doDelete() {
        this.advertisementPackageService.deleteAdvertisementPackage(this.package.id, this.sessionService.getSessionId()).subscribe(data => {
            this.flashService.addMessage("Die Buchung wurde erfolgreich gelöscht.", "success");
            this.router.navigate(['/ad-packages'])
        }, this.errorService.buildFormHandler(this.form, this.formError, {
            always: () => {
                this.openDelete = false;
            }
        }))
    }

    ngOnInit() { }

}