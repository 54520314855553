<nav id="mainNav" class="navbar navbar-default navbar-custom navbar-fixed-top">
    <div class="container">
        <div class="navbar-header page-scroll">
            <!--ul class="nav navbar-nav navbar-right navbar-toggle" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
            <li>
                <a routerLink="/">Test</a>
            </li>
        </ul-->
            <button
                type="button"
                class="navbar-toggle"
                data-toggle="collapse"
                data-target="#bs-example-navbar-collapse-1"
            >
                <span class="sr-only">Toggle navigation</span
                ><i class="fa fa-bars"></i>
            </button>
            <a class="navbar-toggle menu-link" routerLink="solutions">
                <i class="fa fa-question-circle"></i>
            </a>
            <a class="navbar-toggle menu-link" routerLink="campaign">
                <i class="fa fa-info-circle"></i>
            </a>
            <a
                class="navbar-brand"
                routerLink="/"
                style="background-image: url(./frontend/img/logo-gastivo.png)"
            ></a>
        </div>

        <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
            <ul class="nav navbar-nav navbar-right">
                <li class="hidden">
                    <a routerLink="/"></a>
                </li>
                <li *ngFor="let dropdown of dropdowns" class="dropdown">
                    <a
                        href="#"
                        class="dropdown-toggle"
                        data-toggle="dropdown"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                        >{{dropdown.label}} <span class="caret"></span
                    ></a>
                    <ul class="dropdown-menu">
                        <li *ngFor="let item of dropdown.items">
                            <a
                                [routerLink]="item.routerLink"
                                routerLinkActive="active"
                                >{{item.label}}</a
                            >
                        </li>
                    </ul>
                </li>
                <li *ngFor="let item of menuItems">
                    <a [routerLink]="item.routerLink" routerLinkActive="active"
                        >{{item.label}}</a
                    >
                </li>

                <li *ngIf="sessionService?.isLoggedIn()">
                    <a routerLink="abmelden" routerLinkActive="active"
                        ><i class="fa fa-2x fa-sign-out"></i
                    ></a>
                </li>
                <li *ngIf="!sessionService?.isLoggedIn()">
                    <a routerLink="anmeldung" routerLinkActive="active"
                        >Anmelden</a
                    >
                </li>
            </ul>
        </div>
    </div>
</nav>
