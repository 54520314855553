<div class="localized-urls">
  <div *ngFor="let url of urls">
    <div style="display: flex; align-items: center;">
      <div *ngIf="url.locale"
        style="width: 6ch; overflow: hidden; text-overflow: ellipsis; margin-right: 1rem; font-size: 0.9em; opacity: 0.8;">
        {{url.locale}}
      </div>
      <span *ngIf="!url.url">&ndash;</span>
      <a *ngIf="url.url" [href]="url.url" target="_blank">{{url.url}}</a>
    </div>
  </div>
</div>