<header
    class="empty"
    style="background-image: url(./frontend/img/header-bg.jpg)"
></header>
<div class="container">
    <div class="box">
        <h1>{{user?.name}} bearbeiten</h1>
        <form
            class="form-regular form-horizontal"
            *ngIf="form"
            (ngSubmit)="submitForm()"
            [formGroup]="form"
            [ngClass]="{loading:form?.loading}"
        >
            <div class="row">
                <div class="col-sm-12">
                    <select-check-control
                        [disabled]="disabled"
                        [form]="form"
                        [options]="systemRoles"
                        name="systemRoles"
                        label="Rollen"
                        [showLabel]="true"
                    ></select-check-control>
                </div>
            </div>
        </form>
        <div class="row">
            <div class="col-sm-12">
                <form-error [error]="formError"></form-error>
                <button
                    type="submit"
                    (click)="submitForm()"
                    [disabled]="disabled"
                    class="btn btn-primary"
                >
                    Speichern
                </button>
                <a routerLink="/users" class="btn btn-warning">Zurück</a>
                <div class="pull-right">* Pflichtfeld</div>
            </div>
        </div>
    </div>
</div>
