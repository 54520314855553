<header
    class="empty"
    style="background-image: url(./frontend/img/header-bg.jpg)"
></header>
<div class="container">
    <div class="box">
        <h1>Kampagnen verwalten</h1>
        <h4>Suchen</h4>
        <form (submit)="updateCampaigns()" class="form-inline form-campaign">
            <div class="form-group form-group-sm">
                <label>Name</label>
                <input
                    type="text"
                    class="form-control"
                    name="query"
                    placeholder="Name"
                    [(ngModel)]="query"
                />
            </div>
            <div class="form-group form-group-sm">
                <label>Segment</label>
                <select
                    [(ngModel)]="segment"
                    name="segment"
                    class="form-control"
                >
                    <option value="" selected>Alle</option>
                    <option value="{{s}}" *ngFor="let s of segments">
                        {{s}}
                    </option>
                </select>
            </div>
            <div class="form-group form-group-sm">
                <label>Ab</label>
                <input
                    type="date"
                    class="form-control"
                    placeholder="Start"
                    [(ngModel)]="from"
                    name="from"
                />
            </div>
            <div class="form-group form-group-sm">
                <label>Bis</label>
                <input
                    type="date"
                    class="form-control"
                    placeholder="Ende"
                    [(ngModel)]="to"
                    name="to"
                />
            </div>
            <div class="form-group form-group-sm">
                <div class="checkbox">
                    <label>
                        <input
                            type="checkbox"
                            [(ngModel)]="active"
                            name="active"
                        />
                        <i class="fa fa-square-o"></i
                        ><i class="fa fa-check-square"></i>Nur Aktive
                    </label>
                </div>
            </div>
            <button type="submit" class="btn btn-default btn-sm">Suchen</button>
        </form>
        <table class="table">
            <thead *ngIf="campaigns && campaigns.length > 0">
                <tr>
                    <th (click)="sortList('name')">Name</th>
                    <th (click)="sortList('segment')">Segment</th>
                    <th (click)="sortList('validFrom')">Start</th>
                    <th (click)="sortList('validTo')">Ende</th>
                    <th (click)="sortList('priority')">Prio</th>
                    <th (click)="sortList('articleIds?.length')">Artikel</th>
                    <th (click)="sortList('active')">Aktiv</th>
                    <th></th>
                </tr>
            </thead>
            <thead *ngIf="!campaigns || campaigns.length === 0">
                <tr>
                    <th>Name</th>
                    <th>Segment</th>
                    <th>Start</th>
                    <th>Ende</th>
                    <th>Prio</th>
                    <th>Artikel</th>
                    <th>Aktiv</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let c of campaigns">
                    <td>{{c.name}}</td>
                    <td>{{c.segment}}</td>
                    <td>{{c.validFrom|date:'dd.MM.yy'}}</td>
                    <td>{{c.validTo|date:'dd.MM.yy'}}</td>
                    <td>{{c.priority}}</td>
                    <td>{{c.articleIds?.length}}</td>
                    <td>
                        <i
                            class="fa fa-circle"
                            [ngClass]="{'text-success':c.active, 'text-danger':!c.active}"
                        ></i>
                    </td>
                    <td>
                        <a
                            routerLink="/campaign/{{c.id}}"
                            class="btn btn-primary btn-xs"
                            >Bearbeiten</a
                        >
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="alert alert-info" *ngIf="!campaigns?.length">
            Es gibt zur Zeit keine Kampagnen die der Suche entsprechen.
        </div>
        <p>
            <a routerLink="/campaign/create" class="btn btn-primary"
                >Neue Kampagne erstellen</a
            >
        </p>
    </div>
</div>
