
import { enableProdMode } from '@angular/core';
let base = 'gastivo.de';
let config = {
  contentApiPath: 'https://api.' + base + '/content',
  shopApiPath: 'https://api.' + base + '/shop',
  userApiPath: 'https://api.' + base + '/user',
  solutionApiPath: 'https://api.' + base + '/solution',
  productApiPath: 'https://api.' + base + '/product',
  applicationName: 'Gastivo',
  cookieDomain: base,
  portalUrlCh: 'https://www.gastivo.ch',
  portalUrlDe: 'https://www.gastivo.de'
}
if (process.env.ENV == 'develop') {
  config = {
    contentApiPath: 'http://localhost:8083',
    shopApiPath: 'http://localhost:8085',
    userApiPath: 'http://localhost:8087',
    solutionApiPath: 'http://localhost:8081',
    productApiPath: 'http://localhost:8080',
    applicationName: 'Gastivo',
    cookieDomain: 'localhost',
    portalUrlCh: 'http://localhost:3001',
    portalUrlDe: 'http://localhost:3000'
  }
} else {
  enableProdMode();
}
export const Config = config;