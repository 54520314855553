<header
    class="empty"
    style="background-image: url(./frontend/img/header-bg.jpg)"
></header>
<div class="container">
    <div class="box">
        <h1 translate>SUPPLIERS_TITLE</h1>
        <section>
            <form
                (submit)="doSearch()"
                (reset)="doSearch()"
                class="form-inline"
            >
                <div class="form-group">
                    <div class="input-group">
                        <input
                            type="search"
                            name="search"
                            [(ngModel)]="query"
                            class="form-control"
                        />
                        <div *ngIf="enableBusinessUnit" class="input-group-btn">
                            <select
                                name="businessUnitFilter"
                                [(ngModel)]="businessUnitFilter"
                                class="form-control"
                            >
                                <option [ngValue]="null">GE filtern...</option>
                                <option
                                    *ngFor="let unit of businessUnits | keyvalue"
                                    [ngValue]="unit.value"
                                >
                                    {{unit.value}}
                                </option>
                            </select>
                        </div>
                        <div class="input-group-btn">
                            <button class="btn btn-default" type="submit">
                                <i class="fa fa-search"></i>
                            </button>
                            <a class="btn btn-default" (click)="clearSearch()">
                                <i class="fa fa-times"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </form>
        </section>
        <section>
            <nav aria-label="Page navigation">
                <ul class="pagination">
                    <li *ngIf="page > 1">
                        <a
                            [routerLink]="pageLink(page - 1)"
                            aria-label="Previous"
                        >
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>
                    <li>
                        <a>{{page}}</a>
                    </li>
                    <li>
                        <a
                            *ngIf="suppliers.length >= LIMIT"
                            [routerLink]="pageLink(page + 1)"
                            aria-label="Next"
                        >
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>
            </nav>
            <table class="table">
                <thead *ngIf="suppliers && suppliers.length > 0">
                    <tr>
                        <th (click)="sortList('name')">Name</th>
                        <th *ngIf="enableBusinessUnit">GE</th>
                        <th (click)="sortList('visibleInPublicPool')" translate>
                            SUPPLIER_VISIBLE_PUBLIC
                        </th>
                        <th
                            (click)="sortList('visibleInPrivatePool')"
                            translate
                        >
                            SUPPLIER_VISIBLE_PRIVATE
                        </th>
                        <th (click)="sortList('creationDate')">Erstellt</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <thead *ngIf="!suppliers || suppliers.length === 0">
                    <tr>
                        <th>Name</th>
                        <th *ngIf="enableBusinessUnit">GE</th>
                        <th translate>SUPPLIER_VISIBLE_PUBLIC</th>
                        <th translate>SUPPLIER_VISIBLE_PRIVATE</th>
                        <th>Erstellt</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let supplier of suppliers">
                        <td>{{supplier.name}}</td>
                        <td *ngIf="enableBusinessUnit">
                            {{supplier.businessUnit}}
                        </td>
                        <td>
                            <i
                                class="fa"
                                [ngClass]="{'fa-check text-success':supplier.visibleInPublicPool, 'fa-times text-danger':!supplier.visibleInPublicPool}"
                            ></i>
                        </td>
                        <td>
                            <i
                                class="fa"
                                [ngClass]="{'fa-check text-success':supplier.visibleInPrivatePool, 'fa-times text-danger':!supplier.visibleInPrivatePool}"
                            ></i>
                        </td>
                        <td>{{supplier.creationDate|date:'dd.MM.yy HH:mm'}}</td>
                        <td class="text-right">
                            <a
                                routerLink="/supplier/{{supplier.id}}"
                                class="btn btn-default btn-sm"
                                translate
                                >DETAILS</a
                            >
                        </td>
                    </tr>
                </tbody>
            </table>
            <nav aria-label="Page navigation">
                <ul class="pagination">
                    <li *ngIf="page > 1">
                        <a
                            [routerLink]="pageLink(page - 1)"
                            aria-label="Previous"
                        >
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>
                    <li>
                        <a>{{page}}</a>
                    </li>
                    <li>
                        <a
                            *ngIf="suppliers.length >= LIMIT"
                            [routerLink]="pageLink(page + 1)"
                            aria-label="Next"
                        >
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </section>
    </div>
</div>
