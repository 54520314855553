import { Component, OnInit } from '@angular/core';
import { SessionService } from "../services/session.service";

@Component({
    
    selector: 'start',
    templateUrl: 'start.html'
})

export class StartComponent implements OnInit {
    constructor(public sessionService: SessionService) {
        sessionService.onLoggedIn(() => { });
     }

    ngOnInit() { }
}