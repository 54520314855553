<header
    class="empty"
    style="background-image: url(./frontend/img/header-bg.jpg)"
></header>
<div class="container" [ngClass]="{'loading white':loading}">
    <div class="box" *ngIf="supplier">
        <h1>{{supplier?.name}}</h1>
        <table class="table">
            <tbody>
                <tr>
                    <td>
                        <strong translate>SUPPLIER_ID</strong>
                    </td>
                    <td>{{supplier.id}}</td>
                </tr>
                <tr>
                    <td>
                        <strong translate>SUPPLIER_NUMBER</strong>
                    </td>
                    <td>{{supplier.idNumber}}</td>
                </tr>
                <tr>
                    <td>
                        <strong translate>SUPPLIER_LOGO</strong>
                    </td>
                    <td>
                        <img
                            class="supplier-logo"
                            *ngIf="supplier.imageUrl"
                            [src]="supplier.imageUrl"
                            alt=""
                        />
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong translate>SUPPLIER_ADDRESS</strong>
                    </td>
                    <td>
                        {{supplier.address.street}}
                        <br />
                        {{supplier.address.zip}} {{supplier.address.city}}
                        <br />
                        {{'COUNTRY_' + supplier.address.country|translate}}
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong translate>SUPPLIER_CREATION_DATE</strong>
                    </td>
                    <td>{{supplier.creationDate | date: 'dd.MM.yy HH:mm'}}</td>
                </tr>
                <tr>
                    <td>
                        <strong translate>SUPPLIER_LAST_UPDATE</strong>
                    </td>
                    <td>{{supplier.lastUpdate | date: 'dd.MM.yy HH:mm'}}</td>
                </tr>
                <tr>
                    <td>
                        <strong translate>SUPPLIER_VISIBLE_PUBLIC</strong>
                    </td>
                    <td>
                        <i
                            class="fa"
                            [ngClass]="{'fa-check':supplier.visibleInPublicPool, 'fa-times':!supplier.visibleInPublicPool}"
                        ></i>
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong translate>SUPPLIER_VISIBLE_PRIVATE</strong>
                    </td>
                    <td>
                        <i
                            class="fa"
                            [ngClass]="{'fa-check':supplier.visibleInPrivatePool, 'fa-times':!supplier.visibleInPrivatePool}"
                        ></i>
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong translate>SUPPLIER_TERMS_URL</strong>
                    </td>
                    <td>
                        <localized-url
                            [jsonString]="supplier.termsUrl"
                        ></localized-url>
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong translate>SUPPLIER_PRIVACY_URL</strong>
                    </td>
                    <td>
                        <localized-url
                            [jsonString]="supplier.privacyUrl"
                        ></localized-url>
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong translate>SUPPLIER_HOMEPAGE_URL</strong>
                    </td>
                    <td>
                        <a [href]="supplier.homepageUrl"
                            >{{supplier.homepageUrl}}</a
                        >
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>Geschäftseinheit</strong>
                    </td>
                    <td>{{supplier.businessUnit}}</td>
                </tr>
            </tbody>
        </table>
        <p>
            <a (click)="showRaw = !showRaw" class="btn btn-default" translate
                >SUPPLIER_SHOW_RAW</a
            >
        </p>
        <pre *ngIf="showRaw">{{supplier|json}}</pre>
        <p>
            <a (click)="goBack()" class="btn btn-primary" translate
                >BUTTON_BACK</a
            >
            <a
                *ngIf="enableCrmUpdate"
                (click)="crmUpdate()"
                class="btn btn-danger pull-right"
                translate
                >SUPPLIER_CRM_UPDATE_BUTTON</a
            >
        </p>
    </div>
</div>
