import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'errors',
    templateUrl: 'errors.html'
})

export class ErrorsComponent implements OnInit {
    code: number = 500;

    constructor(private route:ActivatedRoute) {
        route.params.subscribe(params => {
            let e = params['code'];
            if (e) {
                this.code = e;
            }
        })
     }

    ngOnInit() { 
        
    }
}