import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'array-control',
    templateUrl: 'array-control.html'
})
export class ArrayControlComponent implements OnChanges {

  
    @Input('values')
    values: string[] = [];
    
    @Input('class')
    class: string;

    @Input('disabled')
    disabled: boolean;

    @Output('valuesChange')
    valuesChange: EventEmitter<String[]> = new EventEmitter<String[]>();    

    textValue: string;

    constructor() {
    }

    initValues(newValues: string[]) {
        this.textValue = "";
        if (newValues) {
            for (let i = 0; i < newValues.length; i++) {
                const element = newValues[i];
                this.textValue += element;
                if (i < newValues.length - 1) {
                    this.textValue += ", ";
                }
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['values']) {
            let newValues = changes['values'].currentValue;
            this.initValues(newValues);
        }
    }

    updateValues(newValue: string) {
        this.textValue = newValue;
        let tmp = this.textValue.split(",");
        let out: string[] = [];
        for (let i = 0; i < tmp.length; i++) {
            let e = tmp[i].trim();
            if (e && e.length) {
                out.push(e);       
            }
        }
        this.valuesChange.emit(out);
    }

}
