<header
    class="empty"
    style="background-image: url(./frontend/img/header-bg.jpg)"
></header>
<div class="container">
    <div class="box">
        <h1>Ein Fehler ist aufgetreten</h1>
        <div class="alert alert-danger" [ngSwitch]="code">
            <div *ngSwitchCase="404">Die Seite wurde nicht gefunden.</div>
            <div *ngSwitchDefault>
                Es ist ein interner Fehler aufgetreten, bitte wenden Sie sich an
                den Support.<br />Fehlercode: <b>{{code}}</b>
            </div>
        </div>
    </div>
</div>
