<header
    class="empty"
    style="background-image: url(./frontend/img/header-bg.jpg)"
></header>
<div class="container">
    <div class="box">
        <h1>Benutzer verwalten</h1>
        <section>
            <form
                (submit)="doSearch()"
                (reset)="doSearch()"
                class="form-inline"
            >
                <div class="form-group">
                    <div class="input-group">
                        <input
                            type="search"
                            name="search"
                            [(ngModel)]="query"
                            class="form-control"
                        />
                        <div *ngIf="enableBusinessUnit" class="input-group-btn">
                            <select
                                name="businessUnitFilter"
                                [(ngModel)]="businessUnitFilter"
                                class="form-control"
                            >
                                <option [ngValue]="null">GE filtern...</option>
                                <option
                                    *ngFor="let unit of businessUnits | keyvalue"
                                    [ngValue]="unit.value"
                                >
                                    {{unit.value}}
                                </option>
                            </select>
                        </div>
                        <div class="input-group-btn">
                            <button class="btn btn-default" type="submit">
                                <i class="fa fa-search"></i>
                            </button>
                            <a class="btn btn-default" (click)="clearSearch()">
                                <i class="fa fa-times"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </form>
        </section>
        <section>
            <nav aria-label="Page navigation">
                <ul class="pagination">
                    <li *ngIf="page > 1">
                        <a
                            [routerLink]="pageLink(page - 1)"
                            aria-label="Previous"
                        >
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>
                    <li>
                        <a>{{page}}</a>
                    </li>
                    <li>
                        <a
                            *ngIf="users.length >= LIMIT"
                            [routerLink]="pageLink(page + 1)"
                            aria-label="Next"
                        >
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>
            </nav>
            <table class="table">
                <thead *ngIf="users && users.length > 0">
                    <tr>
                        <th (click)="sortList('name')">E-Mail</th>
                        <th *ngIf="enableBusinessUnit">GE</th>
                        <th (click)="sortList('creationDate')">Erstellt</th>
                        <th (click)="sortList('status')">Status</th>
                        <th>Rollen</th>
                        <th></th>
                    </tr>
                </thead>
                <thead *ngIf="!users || users.length === 0">
                    <tr>
                        <th>E-Mail</th>
                        <th *ngIf="enableBusinessUnit">GE</th>
                        <th>Erstellt</th>
                        <th>Status</th>
                        <th>Rollen</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user of users">
                        <td>{{user.name}}</td>
                        <td *ngIf="enableBusinessUnit">
                            {{user.businessUnit}}
                        </td>
                        <td>{{user.creationDate|date:'dd.MM.yy HH:mm'}}</td>
                        <td>{{user.status}}</td>
                        <td>{{user.systemRoles?.join(', ')}}</td>
                        <td class="text-right">
                            <a
                                routerLink="/user/{{user.id}}"
                                class="btn btn-default btn-sm"
                                >Details</a
                            >
                            <a
                                *ngIf="enableRoles"
                                routerLink="/user-roles/{{user.id}}"
                                class="btn btn-default btn-sm"
                                >System- Rollen</a
                            >
                            <a
                                *ngIf="enableTokenLogin"
                                routerLink="/token-login/{{user.id}}"
                                class="btn btn-default btn-sm"
                                >Anmelden</a
                            >
                        </td>
                    </tr>
                </tbody>
            </table>
            <nav aria-label="Page navigation">
                <ul class="pagination">
                    <li *ngIf="page > 1">
                        <a
                            [routerLink]="pageLink(page - 1)"
                            aria-label="Previous"
                        >
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>
                    <li>
                        <a>{{page}}</a>
                    </li>
                    <li>
                        <a
                            *ngIf="users.length >= LIMIT"
                            [routerLink]="pageLink(page + 1)"
                            aria-label="Next"
                        >
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>
            </nav>
            <p class="text-right">
                <a (click)="exportCsv()" class="btn btn-default" translate
                    >Als CSV exportieren</a
                >
            </p>
        </section>
    </div>
</div>
