import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { SessionService } from '../services/session.service'
import {
    User,
    Business,
    PublicService,
    AdminService,
    AccessCounter,
    SystemRole,
} from '../../../client-user/index'
import { ErrorService } from '../services/error-handle.service'
import { FlashService } from '../flash/flash.service'
import moment from 'moment'
@Component({
    selector: 'user-detail',
    templateUrl: 'user-detail.html',
})
export class UserDetailComponent implements OnInit {
    user: User
    masterBusiness: Business
    acceptInviteLink: String
    loading: boolean = true
    enableCrmUpdate: boolean = false
    enableTokenLogin: boolean = false
    errorHandler = this.errorService.buildSimpleHandler({
        statusHandles: {
            default: (code: any) => {
                this.router.navigate(['/error/' + code])
            },
        },
    })
    accessCounter: AccessCounter | null = null

    constructor(
        public sessionService: SessionService,
        private adminService: AdminService,
        private publicService: PublicService,
        private route: ActivatedRoute,
        private router: Router,
        private errorService: ErrorService,
        private flashService: FlashService
    ) {
        sessionService.onHasRole(
            SystemRole.BUSINESS_UNIT_USER_ADMIN,
            () => {
                this.enableCrmUpdate = sessionService.hasRole(
                    SystemRole.USER_ADMIN
                )
                this.enableTokenLogin = sessionService.hasRole(
                    SystemRole.BUSINESS_UNIT_USER_ADMIN
                )
                this.route.params.subscribe((params) => {
                    let id = params['id']
                    if (id) {
                        this.adminService
                            .adminGetUser(
                                id,
                                this.sessionService.getSessionId()
                            )
                            .subscribe((c) => {
                                this.user = c
                                this.accessCounter = c.failedLogins
                                this.loading = false
                                this.adminService
                                    .adminGetMasterBusiness(
                                        this.user.customerId,
                                        this.sessionService.getSessionId()
                                    )
                                    .subscribe((b) => {
                                        this.masterBusiness = b
                                        this.adminService
                                            .adminGetUserAcceptInviteLink(
                                                id,
                                                this.sessionService.getSessionId()
                                            )
                                            .subscribe((link) => {
                                                this.acceptInviteLink = link
                                            }, this.errorHandler)
                                    }, this.errorHandler)
                            }, this.errorHandler)
                    } else {
                        this.router.navigate(['/users/'])
                    }
                })
            }
        )
    }

    resendInvite() {
        this.publicService.resendAccountInvite(this.user.name).subscribe(() => {
            this.flashService.addMessage(
                'Einladung wurde erneut versendet',
                'success'
            )
        }, this.errorHandler)
    }

    ngOnInit() {}

    goBack() {
        window.history.back()
    }

    crmUpdate() {
        if (!this.enableCrmUpdate) {
            return
        }
        this.loading = true
        this.adminService
            .adminCRMUpdateUser(
                this.user.id,
                this.sessionService.getSessionId()
            )
            .subscribe((user) => {
                this.user = user
                this.loading = false
                this.flashService.addMessage('USER_UPDATE_SUCCESS', 'success')
            }, this.errorHandler)
    }

    isLoginLocked = () =>
        this.accessCounter != null && this.accessCounter.count > 0

    attempts() {
        if (this.accessCounter == null) return 0

        return this.accessCounter.count
    }

    lockEnddate() {
        if (this.accessCounter == null) return ''

        const count = this.attempts()

        return moment(this.accessCounter.lastAttempt).add(count, 'seconds')
    }

    removeLock() {
        const userId = this.user.id
        this.loading = true
        this.adminService
            .adminDeleteUserFailedLogins(
                userId,
                this.sessionService.getSessionId()
            )
            .subscribe(() => {
                this.loading = false
                this.accessCounter = null
                this.flashService.addMessage(
                    'LOGIN_LOCKED_REMOVED_SUCCESS',
                    'success'
                )
            }, this.errorHandler)
    }
}
