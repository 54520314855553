import { Component, AfterContentInit } from '@angular/core';
import { SessionService } from '../services/session.service';
import { SystemRole } from '../../../client-user/index';
import { Config } from '../app.config';

type MenuItem = {
  label: string
  routerLink: string
}

type Dropdown = {
  label: string
  items: MenuItem[]
}

@Component({
  selector: 'main-navigation',
  templateUrl: 'main-navigation.html'
})
export class MainNavigationComponent implements AfterContentInit {
  isClub: boolean = Config.cookieDomain == "gastivo.club";
  dropdowns: Dropdown[] = [];
  menuItems: MenuItem[] = [];

  constructor(public sessionService: SessionService) {
    this.dropdowns = []
    this.menuItems = []

    const update = () => {
      this.updateMenuItems(sessionService)
    }

    sessionService.onReady(update)
    sessionService.onChange(update)
  }

  updateMenuItems(sessionService: SessionService) {
    const content = [] as MenuItem[]
    const items = [] as MenuItem[]
    if (sessionService.hasRole(SystemRole.CONTENT_ADMIN)) {
      content.push({ label: 'Startseite', routerLink: 'homepage' })
      content.push({ label: 'Kampagnen', routerLink: 'campaign' })
      content.push({ label: 'Lösungen', routerLink: 'solutions' })
      content.push({ label: 'Vereinbarungen', routerLink: 'legal-agreements' })
    }
    if (sessionService.hasRole(SystemRole.SALES_AGENT)) {
      content.push({ label: 'Werbung', routerLink: 'ad-packages' })
    }
    if (sessionService.hasRole(SystemRole.BUSINESS_UNIT_USER_ADMIN)) {
      content.push({ label: 'Lieferanten', routerLink: 'suppliers' })
      items.push({ label: 'Betriebe', routerLink: 'businesses' })
      items.push({ label: 'Benutzer', routerLink: 'users' })
    }

    const dropdowns: Dropdown[] = []
    if (content.length > 0) {
      dropdowns.push({ label: 'Inhalte', items: content })
    }
    this.dropdowns = dropdowns
    this.menuItems = items
  }

  ngAfterContentInit() {
    $(document).ready(function () {
      // Closes the Responsive Menu on Menu Item Click
      $('.navbar-collapse ul li a').click(function () {
        $('.navbar-toggle:visible').click();
      });

      // Offset for Main Navigation
      $('#mainNav').affix({
        offset: {
          top: 20
        }
      });
    });
  }

}
