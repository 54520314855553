import { Component, OnInit } from '@angular/core'
import { SessionService } from '../services/session.service'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { SystemRole } from '../../../client-user/index'
import {
    AdvertisementPackage,
    AdvertisementPackageService,
} from '../../../client-content/index'
import { Config } from '../app.config'
import { sortObjectArrByProps } from '@nighly/sort-object-array-by-property'

@Component({
    selector: 'ad-package',
    templateUrl: 'ad-packages.html',
})
export class AdPackagesComponent implements OnInit {
    lastKeyForSort: string = ''
    listIsReversed: boolean = false
    adPackages: AdvertisementPackage[]
    query: string

    constructor(
        public sessionService: SessionService,
        private adPackagesService: AdvertisementPackageService,
        private http: HttpClient
    ) {
        sessionService.onHasRole(SystemRole.CONTENT_ADMIN, () => {
            this.updateAdPackages()
        })
    }

    public updateAdPackages() {
        this.adPackagesService
            .list(this.sessionService.getSessionId(), this.query)
            .subscribe((list) => {
                this.adPackages = list
            })
    }

    public download(index: number) {
        let adPack = this.adPackages[index]
        let name = adPack.name + '.xlsx'
        let headers: Headers = new Headers()
        headers.append(
            'gstv-session',
            String(this.sessionService.getSessionId())
        )
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        }
        this.http
            .get(
                Config.contentApiPath +
                    '/advertisement-package/' +
                    adPack.id +
                    '/excel',
                options
            )
            .subscribe((response) => {
                let a = $("<a style='display: none;'/>")
                //@ts-ignore
                let url = window.URL.createObjectURL(response.blob())
                a.attr('href', url)
                a.attr('download', name)
                $('body').append(a)
                a.click()
                window.URL.revokeObjectURL(url)
                a.remove()
            })
    }

    sortList(paramKey: string) {
        let response: AdvertisementPackage[] = []
        if (this.lastKeyForSort === paramKey && !this.listIsReversed) {
            response = sortObjectArrByProps(this.adPackages, paramKey, 'r')
            this.listIsReversed = true
        } else {
            response = sortObjectArrByProps(this.adPackages, paramKey)
            this.listIsReversed = false
        }
        this.lastKeyForSort = paramKey
        this.adPackages = response
    }

    ngOnInit() {}
}
