/**
 * Userware
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: .
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type BusinessUnit = 'DE' | 'CH';

export const BusinessUnit = {
    DE: 'DE' as BusinessUnit,
    CH: 'CH' as BusinessUnit
};

