import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { SessionService } from '../services/session.service'
import {
    Business,
    AdminService,
    Customer,
    SystemRole,
    BusinessUnit,
} from '../../../client-user/index'
import { ErrorService } from '../services/error-handle.service'
import { FlashService } from '../flash/flash.service'
import { sortObjectArrByProps } from '@nighly/sort-object-array-by-property'

@Component({
    selector: 'businesses',
    templateUrl: 'businesses.html',
})
export class BusinesssComponent implements OnInit {
    lastKeyForSort: string = ''
    listIsReversed: boolean = false
    businesses: Business[] = []
    query: string = ''
    LIMIT = 10
    page: number = 1
    masterOnly: boolean = false
    businessUnits = BusinessUnit
    businessUnitFilter?: BusinessUnit
    enableBusinessUnit: boolean = false
    customers: { [key: string]: Customer } = {}

    constructor(
        public sessionService: SessionService,
        private adminService: AdminService,
        private router: Router,
        private route: ActivatedRoute,
        private errorService: ErrorService,
        private flashService: FlashService
    ) {
        sessionService.onHasRole(
            SystemRole.BUSINESS_UNIT_USER_ADMIN,
            () => {
                this.enableBusinessUnit = sessionService.hasRole(
                    SystemRole.USER_ADMIN
                )
                this.route.params.subscribe((params:any) => {
                    if (params['q']) {
                        this.query = params['q']
                    }
                    let p = params['page']
                    if (p && p > 0) {
                        this.page = parseInt(p)
                    }
                    let m: string = params['masterOnly']
                    this.masterOnly = m == 'true'
                    this.businessUnitFilter =
                        //@ts-ignore
                        BusinessUnit[params['businessUnit']] || null
                    this.getBusinesses()
                })
            }
        )
    }

    sortList(paramKey: string) {
        let response: Business[] = []
        if (this.lastKeyForSort === paramKey && !this.listIsReversed) {
            response = sortObjectArrByProps(this.businesses, paramKey, 'r')
            this.listIsReversed = true
        } else {
            response = sortObjectArrByProps(this.businesses, paramKey)
            this.listIsReversed = false
        }
        this.lastKeyForSort = paramKey
        this.businesses = response
    }

    doSearch() {
        this.page = 1
        let d:any = { page: this.page }
        if (this.query) {
            d['q'] = this.query
        }
        d['masterOnly'] = this.masterOnly
        if (this.businessUnitFilter) {
            d['businessUnit'] = this.businessUnitFilter
        }
        this.router.navigate(['businesses', d])
    }

    clearSearch() {
        this.query = ''
        this.page = 1
        this.businessUnitFilter = null
        this.masterOnly = false
        this.doSearch()
    }

    getBusinesses() {
        let offset = (this.page - 1) * this.LIMIT
        this.adminService
            .adminListBusinesses(
                this.sessionService.getSessionId(),
                this.query,
                null,
                this.masterOnly,
                this.LIMIT,
                offset,
                this.businessUnitFilter
            )
            .subscribe(
                (list) => {
                    this.businesses = list
                },
                this.errorService.buildSimpleHandler({
                    statusHandles: {
                        default500: (er: any) => {
                            this.flashService.addMessage(
                                'Es ist ein interner Fehler aufgetretten. ' +
                                    er,
                                'danger'
                            )
                        },
                    },
                })
            )
    }

    customerForId(id: string) {
        if (this.customers[id]) {
            return this.customers[id]
        }
        return ''
    }

    pageLink(page: number) {
        return [
            '/businesses',
            {
                q: this.query,
                masterOnly: this.masterOnly,
                businessUnit: this.businessUnitFilter,
                page,
            },
        ]
    }

    ngOnInit() {}
}
