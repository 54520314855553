<header
    class="empty"
    style="background-image: url(./frontend/img/header-bg.jpg)"
></header>
<div class="container">
    <div class="box">
        <h1>Startseite verwalten</h1>
        <section>
            <table class="table table-striped" *ngIf="articles?.length">
                <thead *ngIf="articles && articles.length > 0">
                    <tr class="row">
                        <th
                            style="width: 75%"
                            (click)="sortList('article', 'title')"
                        >
                            Angezeigter Artikel
                        </th>
                        <th
                            style="width: 15%"
                            (click)="sortList('article', 'publishDate')"
                        >
                            Published
                        </th>
                        <th style="width: 10%">Auswahl</th>
                    </tr>
                </thead>
                <thead *ngIf="!articles || articles.length === 0">
                    <tr class="row">
                        <th style="width: 75%">Angezeigter Artikel</th>
                        <th style="width: 15%">Published</th>
                        <th style="width: 10%">Auswahl</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let article of articles" class="row">
                        <td>{{article.title}}</td>
                        <td style="width: 15%">
                            {{article.publishDate|date:'dd.MM.yy HH:mm'}}
                        </td>
                        <td
                            *ngIf="article.id != contentConfig?.featuredArticle?.id"
                            style="width: 10%"
                        >
                            <a
                                (click)="setFeaturedArticle(article)"
                                class="btn btn-primary btn-xs"
                                >Festlegen</a
                            >
                        </td>
                        <td
                            *ngIf="article.id == contentConfig?.featuredArticle?.id"
                            style="width: 10%"
                        >
                            <a
                                (click)="setFeaturedArticle(null)"
                                class="btn btn-selected btn-primary btn-xs"
                                >Abwählen</a
                            >
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="alert alert-info" *ngIf="!articles?.length">
                Keine Einträge vorhanden.
            </div>
            <nav aria-label="Page navigation">
                <ul class="pagination">
                    <li *ngIf="articlesPage > 1">
                        <a
                            [routerLink]="['/homepage',{articlesPage: (articlesPage - 1)}]"
                            aria-label="Previous"
                        >
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>
                    <li *ngIf="articlesPage == 1">
                        <span aria-hidden="true">&laquo;</span>
                    </li>
                    <li>
                        <a>{{articlesPage}}</a>
                    </li>
                    <li>
                        <a
                            *ngIf="articles?.length >= LIMIT"
                            [routerLink]="['/homepage', {articlesPage: (articlesPage + 1)}]"
                            aria-label="Next"
                        >
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                    <li *ngIf="articles?.length < LIMIT">
                        <span aria-hidden="true">&raquo;</span>
                    </li>
                </ul>
            </nav>
        </section>
        <section>
            <table class="table table-striped" *ngIf="articles?.length">
                <thead *ngIf="solutions && solutions.length > 0">
                    <tr>
                        <th
                            style="width: 75%"
                            (click)="sortList('solution', 'title')"
                        >
                            Angezeigte Lösung
                        </th>
                        <th
                            style="width: 10%"
                            (click)="sortList('solution', 'publishDate')"
                        >
                            Published
                        </th>
                        <th style="width: 15%">Auswahl</th>
                    </tr>
                </thead>
                <thead *ngIf="!solutions || solutions.length === 0">
                    <tr class="row">
                        <th style="width: 75%">Angezeigte lösungen</th>
                        <th style="width: 10%">Published</th>
                        <th style="width: 15%">Auswahl</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let solution of solutions">
                        <td>{{solution.title}}</td>
                        <td style="width: 15%">
                            {{solution.publishDate|date:'dd.MM.yy HH:mm'}}
                        </td>
                        <td
                            *ngIf="solution.id != contentConfig?.featuredSolution?.id"
                            style="width: 10%"
                        >
                            <a
                                (click)="setFeaturedSolution(solution)"
                                class="btn btn-primary btn-xs"
                                >Festlegen</a
                            >
                        </td>
                        <td
                            *ngIf="solution.id == contentConfig?.featuredSolution?.id"
                            style="width: 10%"
                        >
                            <a
                                (click)="setFeaturedSolution(null)"
                                class="btn btn-selected btn-primary btn-xs"
                                >Abwählen</a
                            >
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="alert alert-info" *ngIf="!articles?.length">
                Keine Einträge vorhanden.
            </div>
            <nav aria-label="Page navigation">
                <ul class="pagination">
                    <li *ngIf="solutionsPage > 1">
                        <a
                            [routerLink]="['/homepage',{solutionsPage: (solutionsPage - 1)}]"
                            aria-label="Previous"
                        >
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>
                    <li *ngIf="solutionsPage == 1">
                        <span aria-hidden="true">&laquo;</span>
                    </li>
                    <li>
                        <a>{{solutionsPage}}</a>
                    </li>
                    <li *ngIf="solutions?.length >= LIMIT">
                        <a
                            [routerLink]="['/homepage', {solutionsPage: (solutionsPage + 1)}]"
                            aria-label="Next"
                        >
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                    <li *ngIf="solutions?.length < LIMIT">
                        <span aria-hidden="true">&raquo;</span>
                    </li>
                </ul>
            </nav>
        </section>
    </div>
</div>
