import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { CustomHttpParameterCodec } from '../../../client-user/encoder'
import { AdminService, BusinessUnit, Customer, SystemRole, User } from '../../../client-user/index'
import { Config } from '../app.config'
import { FlashService } from '../flash/flash.service'
import { ErrorService } from '../services/error-handle.service'
import { SessionService } from '../services/session.service'
import { saveAs } from 'file-saver'
import { sortObjectArrByProps } from '@nighly/sort-object-array-by-property'

@Component({
    selector: 'users',
    templateUrl: 'users.html',
})
export class UsersComponent implements OnInit {
    lastKeyForSort: string = ''
    listIsReversed: boolean = false
    LIMIT = 10
    users: User[] = []
    query: string = ''
    page: number = 1
    customers: { [key: string]: Customer } = {}
    businessUnits = BusinessUnit
    businessUnitFilter?: BusinessUnit
    enableBusinessUnit: boolean = false
    enableTokenLogin: boolean = false
    enableRoles: boolean = false

    constructor(
        public sessionService: SessionService,
        private router: Router,
        private route: ActivatedRoute,
        private adminService: AdminService,
        private errorService: ErrorService,
        private flashService: FlashService,
        private http: HttpClient
    ) {
        sessionService.onHasRole(
            SystemRole.BUSINESS_UNIT_USER_ADMIN,
            () => {
                this.enableBusinessUnit = sessionService.hasRole(
                    SystemRole.USER_ADMIN
                )
                this.enableTokenLogin = sessionService.hasRole(
                    SystemRole.BUSINESS_UNIT_USER_ADMIN
                )
                this.enableRoles = sessionService.hasRole(
                    SystemRole.USER_ADMIN
                )
                this.route.params.subscribe((params) => {
                    if (params['q']) {
                        this.query = params['q']
                    }
                    let p = params['page']
                    if (p && p > 0) {
                        this.page = parseInt(p)
                    }
                    this.businessUnitFilter =
                        //@ts-ignore
                        BusinessUnit[params['businessUnit']] || null
                    this.getUsers()
                })
            }
        )
    }

    sortList(paramKey: string) {
        let response: User[] = []
        if (this.lastKeyForSort === paramKey && !this.listIsReversed) {
            response = sortObjectArrByProps(this.users, paramKey, 'r')
            this.listIsReversed = true
        } else {
            response = sortObjectArrByProps(this.users, paramKey)
            this.listIsReversed = false
        }
        this.lastKeyForSort = paramKey
        this.users = response
    }

    doSearch() {
        this.page = 1
        let d: any = { page: this.page }
        if (this.query) {
            d['q'] = this.query
        }
        if (this.businessUnitFilter) {
            d['businessUnit'] = this.businessUnitFilter
        }
        this.router.navigate(['users', d])
    }

    clearSearch() {
        this.businessUnitFilter = null
        this.query = ''
        this.page = 1
        this.doSearch()
    }

    getUsers() {
        let offset = (this.page - 1) * this.LIMIT
        this.adminService
            .adminListUsers(
                this.sessionService.getSessionId(),
                this.query,
                null,
                null,
                this.LIMIT,
                offset,
                null,
                this.businessUnitFilter
            )
            .subscribe(
                (list) => {
                    this.users = list
                },
                this.errorService.buildSimpleHandler({
                    statusHandles: {
                        default500: (er: any) => {
                            this.flashService.addMessage(
                                'Es ist ein interner Fehler aufgetreten. ' + er,
                                'danger'
                            )
                        },
                    },
                })
            )
    }

    customerForId(id: string) {
        if (this.customers[id]) {
            return this.customers[id]
        }
        return ''
    }

    pageLink(page: number) {
        return [
            '/users',
            {
                q: this.query,
                businessUnit: this.businessUnitFilter,
                page,
            },
        ]
    }

    exportCsv() {
        const headers: HttpHeaders = new HttpHeaders({
            'gstv-session': String(this.sessionService.getSessionId()),
            Accept: 'text/csv',
        })
        let queryParameters = new HttpParams({
            encoder: new CustomHttpParameterCodec(),
        })
        if (this.query !== undefined && this.query !== null) {
            queryParameters = queryParameters.set('q', this.query)
        }
        if (
            this.businessUnitFilter !== undefined &&
            this.businessUnitFilter !== null
        ) {
            queryParameters = queryParameters.set(
                'businessUnit',
                this.businessUnitFilter
            )
        }
        this.http
            .get(`${Config.userApiPath}/admin/users-csv`, {
                headers: headers,
                params: queryParameters,
                responseType: 'blob',
            })
            .subscribe((data) => {
                const blob = new Blob([data], { type: 'text/csv' })
                saveAs(blob, 'users.csv')
            })
    }

    ngOnInit() {}
}
