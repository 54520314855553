<!-- Header -->
<header
    class="empty"
    style="background-image: url(./frontend/img/header-bg.jpg)"
></header>
<div class="container">
    <div class="box">
        <p>Sie werden abgemeldet...</p>
        <div class="loading"></div>
    </div>
</div>
