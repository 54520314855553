import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from "rxjs";
import { SessionService } from "../services/session.service";
import { AdminService, LoginToken, SessionInfo, SystemRole, BusinessUnit } from '../../../client-user/index';
import { ErrorService } from '../services/error-handle.service';
import { FlashService } from "../flash/flash.service";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Config } from "../app.config";

@Component({
  selector: 'token-login',
  templateUrl: 'token-login.html'
})

export class TokenLoginComponent implements OnInit {
  safeLink: SafeUrl;
  link: string;
  token: LoginToken;
  sessionInfos: SessionInfo[];

  constructor(public sessionService: SessionService, private adminService: AdminService, private route: ActivatedRoute, private errorService: ErrorService, private flashService: FlashService, private domSanitizer: DomSanitizer) {

    sessionService.onHasRole(SystemRole.BUSINESS_UNIT_USER_ADMIN, () => {
      this.route.params.subscribe(params => {
        let id = params['id'];
        if (id) {
          let ob1 = this.adminService.getSessionInfo(id, this.sessionService.getSessionId());
          let ob2 = this.adminService.createLoginToken(id, this.sessionService.getSessionId());
          forkJoin(ob1, ob2).subscribe(data => {
            this.sessionInfos = data[0];
            this.token = data[1];
            let portalUrl = Config.portalUrlDe
            if (this.token.user && this.token.user.businessUnit === BusinessUnit.CH) {
              portalUrl = Config.portalUrlCh
            }
            this.link = portalUrl + "/token-login/" + this.token.code;
            this.safeLink = this.domSanitizer.bypassSecurityTrustUrl(this.link);
          }, this.errorService.buildSimpleHandler({
            statusHandles: {
              default500: (er: any) => {
                this.flashService.addMessage("Es ist ein interner Fehler aufgetretten. " + er, "danger");
              }
            }
          }))
        }
      })
    })
  }

  ngOnInit() { }

}