import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionService } from "../services/session.service";
import { WeFormGroup } from '../models';
import { User, AdminService, SystemRole } from '../../../client-user/index';
import { FormControl } from '@angular/forms';
import { ErrorService, FormErrorValue } from '../services/error-handle.service';
import { FlashService } from "../flash/flash.service";

@Component({
  selector: 'user-roles',
  templateUrl: 'user-roles.html'
})

export class UserRolesComponent implements OnInit {
  user: User;
  form: WeFormGroup;
  formError: FormErrorValue = new FormErrorValue();
  disabled: boolean = false;
  systemRoles: { label: string, value: SystemRole }[] =
    [{
      label: "" + SystemRole.CONTENT_ADMIN, value: SystemRole.CONTENT_ADMIN
    },
    {
      label: "" + SystemRole.USER_ADMIN, value: SystemRole.USER_ADMIN
    },
    {
      label: "" + SystemRole.BUSINESS_UNIT_USER_ADMIN, value: SystemRole.BUSINESS_UNIT_USER_ADMIN
    },
    {
      label: "" + SystemRole.SALES_AGENT, value: SystemRole.SALES_AGENT
    },
    {
      label: "" + SystemRole.SYSTEM_ADMIN, value: SystemRole.SYSTEM_ADMIN
    },
    ]

  constructor(public sessionService: SessionService, private adminService: AdminService, private route: ActivatedRoute, private router: Router, private errorService: ErrorService, private flashService: FlashService) {

    sessionService.onHasRole(SystemRole.USER_ADMIN, () => {

      this.route.params.subscribe(params => {
        let id = params['id'];
        if (id) {
          this.adminService.adminGetUser(id, this.sessionService.getSessionId()).subscribe((c) => {
            this.user = c;
            let isSysAdmin = sessionService.hasRole(SystemRole.SYSTEM_ADMIN);
            this.disabled == this.user.systemRoles.indexOf(SystemRole.SYSTEM_ADMIN) > -1 && !isSysAdmin;
            if (!isSysAdmin) {
              this.systemRoles.pop();
            }
            this.initForm();
          }, this.errorService.buildSimpleHandler({
            statusHandles: {
              default: (code: any) => {
                this.router.navigate(['/error/' + code])
              }
            }
          }));
        } else {
          this.router.navigate(['/users/']);
        }

      })
    })
  }

  private initForm() {
    let controls: {} = {
      'systemRoles': new FormControl(this.user.systemRoles),
    };
    this.form = new WeFormGroup(controls);
  }

  public submitForm() {
    this.form.sent = true;
    if (this.form.valid) {
      this.form.loading = true;
      this.adminService.adminUpdateUserSystemRoles(this.user.id, this.sessionService.getSessionId(), this.form.value.systemRoles).subscribe((user) => {
        this.form.loading = false;
        this.flashService.addMessage("Änderungen wurden gespeichert!", "success");
        this.user = user;
      }, this.errorService.buildFormHandler(this.form, this.formError, { isBody: true }));
    }
  }

  ngOnInit() { }

}