<header
    class="empty"
    style="background-image: url(./frontend/img/header-bg.jpg)"
></header>
<div class="container">
    <div class="box">
        <h1>Vereinbarungen verwalten</h1>
        <section>
            <table class="table table-striped" *ngIf="legalAgreements?.length">
                <thead>
                    <tr>
                        <th>Titel</th>
                        <th>Zustimungspflichtig</th>
                        <th>Gültig ab</th>
                        <th>Aktiv</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let agreement of legalAgreements">
                        <td>{{agreement.title}}</td>
                        <td>
                            <i
                                class="fa fa-check text-success"
                                *ngIf="agreement.mandatory"
                            ></i
                            ><i
                                class="fa fa-times text-danger"
                                *ngIf="!agreement.mandatory"
                            ></i>
                        </td>
                        <td>
                            {{agreement.validFromDate|date:'dd.MM.yy HH:mm'}}
                        </td>
                        <td>
                            <i
                                class="fa fa-check text-success"
                                *ngIf="agreement.active"
                            ></i
                            ><i
                                class="fa fa-times text-danger"
                                *ngIf="!agreement.active"
                            ></i>
                        </td>
                        <td>
                            <a
                                routerLink="/legal-agreement/{{agreement.id}}"
                                class="btn btn-primary btn-xs"
                                >Bearbeiten</a
                            >
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="alert alert-info" *ngIf="!legalAgreements?.length">
                Keine Einträge vorhanden.
            </div>
        </section>
        <section>
            <a routerLink="/legal-agreement/create" class="btn btn-primary"
                >Neue Vereinbarung erstellen</a
            >
        </section>
    </div>
</div>
