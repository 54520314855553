/**
 * Userware
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: .
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SystemRole = 'SYSTEM_ADMIN' | 'CONTENT_ADMIN' | 'USER_ADMIN' | 'BUSINESS_UNIT_USER_ADMIN' | 'SALES_AGENT';

export const SystemRole = {
    SYSTEM_ADMIN: 'SYSTEM_ADMIN' as SystemRole,
    CONTENT_ADMIN: 'CONTENT_ADMIN' as SystemRole,
    USER_ADMIN: 'USER_ADMIN' as SystemRole,
    BUSINESS_UNIT_USER_ADMIN: 'BUSINESS_UNIT_USER_ADMIN' as SystemRole,
    SALES_AGENT: 'SALES_AGENT' as SystemRole
};

