import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, ValidatorFn, Validators } from '@angular/forms';

@Component({
    selector: 'error-block',
    templateUrl: 'error-block.html'
})
export class ErrorBlockComponent {

  errorMessages: { [key: string]: string } = {
        "required": "Dies ist ein Pflichtfeld",
        "min": "Der Wert ist zu niedrig.",
        "max": "Der Wert ist zu hoch.",
        "size": "Der Wert hat die falsche Länge.",
        "maxlength": "Der Wert ist zu lang.",
        "minlength": "Der Wert ist zu kurz.",
        "email": "Bitte geben Sie eine gültige E-Mailadressse an.",
        "url" : "Bitte geben Sie eine gültige Internetadresse an.",
        "duplicate": "Dieser Wert existiert mehrfach.",
        "dependency" : "Eine Abhängigkeit existiert.",
        "constraint": "Eine Beschrenkung existiert.",
        "date" : "Bitte geben Sie ein gültiges Datum an"
    }
    @Input("messages")
  messages: { [key: string]: string };
    @Input("ignore")
  ignore: string[] = [];

    @Input("control")
  control: FormControl;

    constructor() {
    }

  getMessage(key: string): string{
        if (this.messages) {
            if (this.messages[key]) {
                return this.messages[key];
            }
        }
        if (this.errorMessages[key]) {
            return this.errorMessages[key];
        }
        return "Unknown error: " + key;
    }

  renderMessages(errors: { [key: string]: boolean }): string[] {
        let out: string[] = [];
        for (let key in errors) {
            if (this.ignore.indexOf(key) > -1) {
                continue;
            }
            out.push(this.getMessage(key));
        }
        return out;
    }
}
