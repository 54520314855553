import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { WeFormGroup } from '../../models';
import { } from '../../error-block/error-block.component';

@Component({
  selector: 'textarea-control',
  templateUrl: 'textarea-control.html'
})
export class TextareaControlComponent {

  @Input('form')
  form: WeFormGroup;
  @Input('name')
  name: string;
  @Input('label')
  public label: string;
  @Input('helpText')
  helpText: string;
  @Input("messages")
  messages: { [key: string]: string };
  @Input("ignore")
  ignore: string[] = [];
  @Input('height')
  height: number;
  @Input("showLabel")
  showLabel: boolean = false;


  constructor() {
  }
}
