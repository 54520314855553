import { Injectable } from '@angular/core';
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { filter, pairwise } from "rxjs/operators";

@Injectable()
export class RouteService {

  private referrer: string;

  constructor(private router: Router) {
    this.router.events.pipe(
      filter((e: any) => {
        return e.urlAfterRedirects && e.state;
      }),
      pairwise()
    ).subscribe((e: any[]) => {
      this.referrer = e[0].urlAfterRedirects;
    });
  }

  public getReferrer(): string {
    return this.referrer;
  }
}