/**
 * Contentware
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: .
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AdvertisementStatus = 'RESERVED' | 'BOOKED' | 'EXPIRED' | 'CANCELED';

export const AdvertisementStatus = {
    RESERVED: 'RESERVED' as AdvertisementStatus,
    BOOKED: 'BOOKED' as AdvertisementStatus,
    EXPIRED: 'EXPIRED' as AdvertisementStatus,
    CANCELED: 'CANCELED' as AdvertisementStatus
};

