import { Component, OnInit } from '@angular/core'
import { SessionService } from '../services/session.service'
import { SystemRole } from '../../../client-user/index'
import {
    DefinitionsService,
    SolutionFlowDefinition,
} from '../../../client-solution/index'
import { ErrorService } from '../services/error-handle.service'
import { FlashService } from '../flash/flash.service'
import { sortObjectArrByProps } from '@nighly/sort-object-array-by-property'

@Component({
    selector: 'solutions',
    templateUrl: 'solutions.html',
})
export class SolutionsComponent implements OnInit {
    lastKeyForSort: string = ''
    listIsReversed: boolean = false
    definitions: SolutionFlowDefinition[] = []
    query: string
    sortBy: 'NAME' | 'CREATION_DATE' | 'LAST_UPDATE'
    sortOrder: 'ASC' | 'DESC'

    constructor(
        public sessionService: SessionService,
        private definitionsService: DefinitionsService,
        private errorService: ErrorService,
        private flashService: FlashService
    ) {
        sessionService.onHasRole(SystemRole.CONTENT_ADMIN, () => {
            this.updateFlows()
        })
    }

    updateFlows() {
        this.definitionsService
            .listFlows(
                this.sessionService.getSessionId(),
                this.query,
                this.sortBy,
                this.sortOrder
            )
            .subscribe(
                (list) => {
                    this.definitions = list
                },
                this.errorService.buildSimpleHandler({
                    statusHandles: {
                        default500: (er: any) => {
                            this.flashService.addMessage(
                                'Es ist ein interner Fehler aufgetretten. ' +
                                    er,
                                'danger'
                            )
                        },
                    },
                })
            )
    }

    sortList(paramKey: string) {
        let response: SolutionFlowDefinition[] = []
        if (this.lastKeyForSort === paramKey && !this.listIsReversed) {
            response = sortObjectArrByProps(this.definitions, paramKey, 'r')
            this.listIsReversed = true
        } else {
            response = sortObjectArrByProps(this.definitions, paramKey)
            this.listIsReversed = false
        }
        this.lastKeyForSort = paramKey
        this.definitions = response
    }

    ngOnInit() {}
}
