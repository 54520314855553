import {
    Component,
    ViewChild,
    AfterContentInit,
    ApplicationRef,
} from '@angular/core'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { RouteService } from './services/route.service'

@Component({
    selector: 'app',
    templateUrl: 'app.html',
})
export class AppComponent implements AfterContentInit {
    constructor(
        private applicationRef: ApplicationRef,
        private router: Router,
        translate: TranslateService,
        private routeService: RouteService
    ) {
        translate.setDefaultLang('de_de')
        translate.use('de_de')
    }

    ngAfterContentInit() {
        $('body').removeClass('loading')
    }
}
