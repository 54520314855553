import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PublicService } from '../../../client-user/index';
import { SessionService } from '../services/session.service';
import { ErrorService } from '../services/error-handle.service';

@Component({
    selector: 'user-logout',
    templateUrl: 'user-logout.html'
})
export class UserLogoutComponent implements OnInit {

    constructor(private publicService: PublicService, private errorService: ErrorService, private router: Router, private sessionService: SessionService) {
       
        this.sessionService.onReady(() => {
            if (this.sessionService.isLoggedIn()) {
                publicService.logout(this.sessionService.getSessionId()).subscribe(data => {
                    sessionService.clear();
                    this.router.navigate(['']);
                }, () => {
                    sessionService.clear();
                    this.router.navigate(['']);
                });
            } else {
                this.router.navigate(['']);
            }
        })
    }

    ngOnInit() {
    }

}
