<header
    class="empty"
    style="background-image: url(./frontend/img/header-bg.jpg)"
></header>
<div class="container">
    <div class="box">
        <h1>Werbung verwalten</h1>
        <section>
            <form (submit)="updateAdPackages()" class="form-inline">
                <div class="form-group form-group-sm">
                    <input
                        type="text"
                        class="form-control"
                        name="query"
                        placeholder="Name"
                        [(ngModel)]="query"
                    />
                </div>
                <button type="submit" class="btn btn-default btn-sm">
                    Suchen
                </button>
            </form>
        </section>
        <section>
            <table class="table">
                <thead *ngIf="adPackages && adPackages.length > 0">
                    <tr>
                        <th (click)="sortList('creationDate')">Erstellt</th>
                        <th (click)="sortList('name')">Name</th>
                        <th (click)="sortList('advertisements?.length')">
                            Anzahl Schaltungen
                        </th>
                        <th (click)="sortList('status')">Status</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <thead *ngIf="!adPackages || adPackages.length === 0">
                    <tr>
                        <th>Erstellt</th>
                        <th>Name</th>
                        <th>Anzahl Schaltungen</th>
                        <th>Status</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let c of adPackages; index as i;">
                        <td>{{c.creationDate|date:'dd.MM.yy'}}</td>
                        <td>{{c.name}}</td>
                        <td>{{c.advertisements?.length}}</td>
                        <td>{{c.status}}</td>
                        <td>
                            <a
                                class="btn btn-default btn-xs pull-right"
                                (click)="download(i)"
                                ><i class="fa fa-download"></i> Download</a
                            >
                        </td>
                        <td>
                            <a
                                routerLink="/ad-package/{{c.id}}"
                                class="btn btn-primary btn-xs pull-right"
                                >Bearbeiten</a
                            >
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="alert alert-info" *ngIf="!adPackages?.length">
                Es gibt zur Zeit keine Werbepakete die der Suche entsprechen.
            </div>
        </section>
    </div>
</div>
