<header
    class="empty"
    style="background-image: url(./frontend/img/header-bg.jpg)"
></header>
<div class="container">
    <div class="box">
        <h1>
            Buchung bearbeiten
            <a routerLink="/ad-packages" class="btn btn-default pull-right"
                >Zurück</a
            >
        </h1>
        <section>
            <form
                class="form-horizontal"
                *ngIf="form"
                (ngSubmit)="submitForm()"
                [formGroup]="form"
                [ngClass]="{loading:form?.loading}"
            >
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form-group">
                            <div class="col-sm-12">
                                <label>ID</label>
                                <div class="form-control-static">
                                    {{package?.id}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <input-control
                            name="name"
                            label="Name *"
                            [form]="form"
                            type="text"
                            [showLabel]="true"
                        ></input-control>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <textarea-control
                            name="notes"
                            label="Anmerkungen"
                            [form]="form"
                            type="text"
                            [showLabel]="true"
                        ></textarea-control>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <select-control
                            name="status"
                            label="Status *"
                            [form]="form"
                            type="text"
                            [showLabel]="true"
                            [options]="statuses"
                        ></select-control>
                    </div>
                </div>
            </form>
        </section>
        <section>
            <div class="form-group">
                <h4>Schaltungen</h4>
                <table
                    class="table"
                    *ngIf="package?.advertisements?.length > 0"
                >
                    <thead>
                        <tr>
                            <th>Typ</th>
                            <th>Start</th>
                            <th>Ende</th>
                            <th>Status</th>
                            <th>Eigenproduktion</th>
                            <th>Anmerkungen</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container
                            *ngFor="let ad of package?.advertisements; index as i;"
                        >
                            <tr>
                                <td>{{ad.type}}</td>
                                <td>
                                    {{ad.startDate | date:'dd.MM.yy HH:mm'}}
                                </td>
                                <td>{{ad.endDate | date:'dd.MM.yy HH:mm'}}</td>
                                <td>{{ad.status}}</td>
                                <td>
                                    <i class="fa fa-check"></i>
                                </td>
                                <td>{{ad.notes}}</td>
                                <td>
                                    <a
                                        (click)="removeAdvertisement(i)"
                                        class="btn btn-danger btn-sm pull-right"
                                        ><i class="fa fa-trash"></i
                                    ></a>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
                <div
                    class="alert alert-info"
                    *ngIf="!package?.advertisements || package?.advertisements?.length == 0"
                >
                    Keine Schaltungen vorhanden.
                </div>
            </div>
        </section>
        <section>
            <form-error [error]="formError"></form-error>
            <button
                type="submit"
                (click)="submitForm()"
                class="btn btn-primary"
            >
                Speichern
            </button>
            <a routerLink="/ad-packages" class="btn btn-default">Abbrechen</a>
            <a (click)="openDelete = true" class="btn btn-danger">Löschen</a>
            <div class="pull-right">* Pflichtfeld</div>
        </section>
    </div>
</div>
<ng-container *ngIf="openDelete">
    <div class="modal modal-show" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button
                        type="button"
                        class="close"
                        (click)="openDelete = false"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 class="modal-title">Löschen bestätigen</h4>
                </div>
                <div class="modal-body">
                    <p>Wollen Sie diese Buchung wirklich löschen?</p>
                </div>
                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-default"
                        (click)="openDelete = false"
                    >
                        Nein
                    </button>
                    <button
                        type="button"
                        class="btn btn-primary"
                        (click)="doDelete()"
                    >
                        Ja
                    </button>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
    <div class="modal-backdrop in"></div>
</ng-container>
