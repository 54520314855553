import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { SessionService } from '../services/session.service'
import { User, Supplier, AdminService, SystemRole } from '../../../client-user/index'
import { ErrorService } from '../services/error-handle.service'
import { FlashService } from '../flash/flash.service'

@Component({
    selector: 'supplier-detail',
    templateUrl: 'supplier-detail.html',
})
export class SupplierDetailComponent implements OnInit {
    supplier: Supplier
    masterSupplier: Supplier
    childSupplieres: Supplier[] = []
    users: User[] = []
    loading: boolean = true
    enableCrmUpdate: boolean = false

    errorHandler = this.errorService.buildSimpleHandler({
        statusHandles: {
            default: (code: any) => {
                this.router.navigate(['/error/' + code])
            },
        },
    })

    constructor(
        public sessionService: SessionService,
        private adminService: AdminService,
        private route: ActivatedRoute,
        private router: Router,
        private errorService: ErrorService,
        private flashService: FlashService
    ) {
        sessionService.onHasRole(
            SystemRole.BUSINESS_UNIT_USER_ADMIN,
            () => {
                this.enableCrmUpdate = sessionService.hasRole(
                    SystemRole.USER_ADMIN
                )
                this.route.params.subscribe((params: any) => {
                    let id = params['id']
                    if (id) {
                        this.adminService
                            .adminGetSupplierById(
                                id,
                                this.sessionService.getSessionId()
                            )
                            .subscribe((c) => {
                                this.supplier = c
                                this.loading = false
                            }, this.errorHandler)
                    } else {
                        this.router.navigate(['/suppliers/'])
                    }
                })
            }
        )
    }

    ngOnInit() {}

    goBack() {
        window.history.back()
    }

    crmUpdate() {
        this.loading = true
        this.adminService
            .adminCRMFullUpdateSupplier(
                this.supplier.id,
                this.sessionService.getSessionId()
            )
            .subscribe((supplier) => {
                this.supplier = supplier
                this.flashService.addMessage(
                    'BUSINESS_UPDATE_SUCCESS',
                    'success'
                )
                this.loading = false
            }, this.errorHandler)
    }
}
