import { NgModule } from '@angular/core'
import { HttpClient, HttpClientModule } from '@angular/common/http'
import { BrowserModule } from '@angular/platform-browser'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { TranslateModule, TranslateLoader } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'

import { routing } from './app.routing'
import { AppComponent } from './app.component'
import { MainNavigationComponent } from './main-navigation/main-navigation.component'
import { UserLoginComponent } from './user-login/user-login.component'
import { UserLogoutComponent } from './user-logout/user-logout.component'
import { UsersComponent } from './users/users.component'
import { UserDetailComponent } from './user-detail/user-detail.component'
import { UserRolesComponent } from './user-roles/user-roles.component'
import { BusinesssComponent } from './businesses/businesses.component'
import { BusinessDetailComponent } from './business-detail/business-detail.component'
import { SolutionsComponent } from './solutions/solutions.component'
import { SolutionDetailComponent } from './solution-detail/solution-detail.component'
import { CampaignsComponent } from './campaigns/campaigns.component'
import { CampaignDetailComponent } from './campaign-detail/campaign-detail.component'
import { LegalAgreementsComponent } from './legal-agreements/legal-agreements.component'
import { LegalAgreementDetailComponent } from './legal-agreement-detail/legal-agreement-detail.component'
import { StartComponent } from './start/start.component'
import { ErrorsComponent } from './errors/errors.component'
import { AdPackagesComponent } from './ad-packages/ad-packages.component'
import { AdPackageDetailComponent } from './ad-package-detail/ad-package-detail.component'
import { FlashModule } from './flash/flash.module'
import { ErrorBlockComponent } from './error-block/error-block.component'
import { InputControlComponent } from './form-controls/input-control/input-control.component'
import { SelectControlComponent } from './form-controls/select-control/select-control.component'
import { TextareaControlComponent } from './form-controls/textarea-control/textarea-control.component'
import { TimeControlComponent } from './form-controls/time-control/time-control.component'
import { SelectCheckControlComponent } from './form-controls/select-check-control/select-check-control.component'
import { TokenLoginComponent } from './token-login/token-login.component'
import { ArrayControlComponent } from './array-control/array-control.component'

import { APIS, BASE_PATH } from '../../client-content/index'
import {
    APIS as USER_APIS,
    BASE_PATH as BASE_PATH3,
} from '../../client-user/index'
import {
    APIS as SOLUTION_APIS,
    BASE_PATH as BASE_PATH4,
} from '../../client-solution/index'
import { URLFriendlyPipe, ShortenPipe, NL2BRPipe } from './pipes/pipes'
import { MetadataService } from './services/metadata.service'
import { ErrorService, FormError } from './services/error-handle.service'
import { SessionService } from './services/session.service'
import { CookieService } from './services/cookie.service'
import { RouteService } from './services/route.service'
import { LangService } from './services/lang.service'
import { Config } from './app.config'
import { UrlSerializer } from '@angular/router'
import { CustomUrlSerializer } from './helper/custom_url_serializer'
import { FileInputDirective, FileControlComponent } from './files'
import { SupplierDetailComponent } from './supplier-detail/supplier-detail.component'
import { SuppliersComponent } from './suppliers/suppliers.component'
import { HomepageSettings } from './homepage-settings/homepage-settings'
import { LocalizedUrlComponent } from './localized-url/localized-url.component'

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '/frontend/i18n/')
}

@NgModule({
    declarations: [
        AppComponent,
        MainNavigationComponent,
        UserLoginComponent,
        URLFriendlyPipe,
        ShortenPipe,
        NL2BRPipe,
        FormError,
        ErrorBlockComponent,
        InputControlComponent,
        SelectControlComponent,
        UserLogoutComponent,
        TextareaControlComponent,
        SelectCheckControlComponent,
        TimeControlComponent,
        UsersComponent,
        SolutionsComponent,
        SolutionDetailComponent,
        CampaignsComponent,
        StartComponent,
        CampaignDetailComponent,
        ErrorsComponent,
        AdPackagesComponent,
        AdPackageDetailComponent,
        UserDetailComponent,
        UserRolesComponent,
        LegalAgreementsComponent,
        LegalAgreementDetailComponent,
        TokenLoginComponent,
        BusinesssComponent,
        BusinessDetailComponent,
        ArrayControlComponent,
        FileInputDirective,
        FileControlComponent,
        SupplierDetailComponent,
        SuppliersComponent,
        HomepageSettings,
        LocalizedUrlComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        routing,
        ReactiveFormsModule,
        FormsModule,
        FlashModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
    ],
    bootstrap: [AppComponent],
    providers: [
        [{ provide: BASE_PATH, useValue: Config.contentApiPath }, APIS],
        [{ provide: BASE_PATH3, useValue: Config.userApiPath }, USER_APIS],
        [
            { provide: BASE_PATH4, useValue: Config.solutionApiPath },
            SOLUTION_APIS,
        ],
        { provide: UrlSerializer, useClass: CustomUrlSerializer },
        MetadataService,
        ErrorService,
        SessionService,
        CookieService,
        RouteService,
        LangService,
    ],
})
export class AppModule {}
