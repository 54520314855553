import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { SessionService } from '../services/session.service'
import { WeFormGroup } from '../models'
import { LegalAgreement, AdminService, SystemRole } from '../../../client-user/index'
import { FormControl, Validators } from '@angular/forms'
import { ErrorService, FormErrorValue } from '../services/error-handle.service'
import { FlashService } from '../flash/flash.service'
import moment from 'moment'
@Component({
    selector: 'legal-agreement-detail',
    templateUrl: 'legal-agreement-detail.html',
})
export class LegalAgreementDetailComponent implements OnInit {
    form: WeFormGroup
    formError: FormErrorValue = new FormErrorValue()
    agreement: LegalAgreement
    isCreate: boolean = true
    openDelete: boolean = false

    constructor(
        public sessionService: SessionService,
        public adminService: AdminService,
        private route: ActivatedRoute,
        private router: Router,
        private errorService: ErrorService,
        private flashService: FlashService
    ) {
        sessionService.onHasRole(SystemRole.CONTENT_ADMIN, () => {
            this.route.params.subscribe((params) => {
                let id = params['id']
                if (id) {
                    this.isCreate = false
                    this.adminService
                        .adminGetLegalAgreement(
                            id,
                            this.sessionService.getSessionId()
                        )
                        .subscribe(
                            (data) => {
                                this.agreement = data
                                this.initForm()
                            },
                            this.errorService.buildSimpleHandler({
                                statusHandles: {
                                    default: (code: any) => {
                                        this.router.navigate(['/error/' + code])
                                    },
                                },
                            })
                        )
                } else {
                    this.agreement = {
                        title: '',
                        content: '',
                        active: false,
                        mandatory: false,
                        autoConfirmNewUsers: true,
                        validFromDate: moment(new Date())
                            .add(1, 'days')
                            .startOf('day')
                            .toDate(),
                        confirmText: 'Ich stimme der Vereinbarung zu',
                        declineText: 'Ich lehne die Vereinbarung ab',
                    }
                    this.initForm()
                }
            })
        })
    }

    private initForm() {
        let controls: {} = {
            title: new FormControl(this.agreement.title, Validators.required),
            content: new FormControl(
                this.agreement.content,
                Validators.required
            ),
            confirmText: new FormControl(
                this.agreement.confirmText,
                Validators.required
            ),
            declineText: new FormControl(
                this.agreement.declineText,
                Validators.required
            ),
            active: new FormControl(this.agreement.active),
            mandatory: new FormControl(this.agreement.mandatory),
            autoConfirmNewUsers: new FormControl(
                this.agreement.autoConfirmNewUsers
            ),
            validFromDate: new FormControl(
                moment(this.agreement.validFromDate).format('YYYY-MM-DD'),
                Validators.required
            ),
        }
        this.form = new WeFormGroup(controls)
    }

    public submitForm() {
        this.form.sent = true
        if (this.form.valid) {
            this.form.loading = true
            this.agreement = { ...this.agreement, ...this.form.value }
            this.agreement.validFromDate = new Date(
                this.agreement.validFromDate
            )
            this.agreement.validFromDate.toISOString = function () {
                return moment.utc(this).format('YYYY-MM-DDTHH:mm:ss\\Z')
            }
            if (this.isCreate) {
                this.adminService
                    .adminCreateLegalAgreement(
                        this.sessionService.getSessionId(),
                        this.agreement
                    )
                    .subscribe((newAgreement) => {
                        this.flashService.addMessage(
                            'Neue Vereinbarung erstellt!',
                            'success'
                        )
                        this.router.navigate([
                            '/legal-agreement/' + newAgreement.id,
                        ])
                        this.form.loading = false
                    }, this.errorService.buildFormHandler(this.form, this.formError))
            } else {
                this.adminService
                    .adminUpdateLegalAgreement(
                        this.agreement.id,
                        this.sessionService.getSessionId(),
                        this.agreement
                    )
                    .subscribe((result) => {
                        this.form.loading = false
                        this.flashService.addMessage(
                            'Änderungen wurden gespeichert!',
                            'success'
                        )
                    }, this.errorService.buildFormHandler(this.form, this.formError))
            }
        }
    }

    public doDelete() {
        this.adminService
            .adminDeleteLegalAgreement(
                this.agreement.id,
                this.sessionService.getSessionId()
            )
            .subscribe(
                (data) => {
                    this.flashService.addMessage(
                        'Die Vereinbarung wurde erfolgreich gelöscht.',
                        'success'
                    )
                    this.router.navigate(['/legal-agreements'])
                },
                this.errorService.buildFormHandler(this.form, this.formError, {
                    always: () => {
                        this.openDelete = false
                    },
                })
            )
    }

    ngOnInit() {}
}
