<header
    class="empty"
    style="background-image: url(./frontend/img/header-bg.jpg)"
></header>
<div class="container" [ngClass]="{'loading white':loading}">
    <div class="box" *ngIf="business">
        <h1>{{business?.name}}</h1>
        <table class="table">
            <tbody>
                <tr>
                    <td>
                        <strong translate>BUSINESS_ACCOUNT_NUMBER</strong>
                    </td>
                    <td>{{business.accountNumber}}</td>
                </tr>
                <tr>
                    <td>
                        <strong translate>BUSINESS_DELIVERY_ADDRESS</strong>
                    </td>
                    <td>
                        {{business.deliveryAddress.street}}
                        <br />
                        {{business.deliveryAddress.zip}}
                        {{business.deliveryAddress.city}} <br />
                        {{'COUNTRY_' +
                        business.deliveryAddress.country|translate}}
                    </td>
                </tr>
                <tr *ngIf="business.invoiceAddress">
                    <td>
                        <strong translate>BUSINESS_INVOICE_ADDRESS</strong>
                    </td>
                    <td>
                        {{business.invoiceAddress.street}}
                        <br />
                        {{business.invoiceAddress.zip}}
                        {{business.invoiceAddress.city}} <br />
                        {{'COUNTRY_' +
                        business.invoiceAddress.country|translate}}
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong translate>BUSINESS_IS_MASTER</strong>
                    </td>
                    <td>
                        <i
                            class="fa"
                            [ngClass]="{'fa-check':business.master, 'fa-times':!business.master}"
                        ></i>
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong translate>BUSINESS_USERS</strong>
                    </td>
                    <td>
                        <div *ngFor="let user of users">
                            <a routerLink="/user/{{user.id}}"
                                >{{user.firstName}} {{user.lastName}}
                                {{getBusinessRole(user)? '- ' + ('USER_ROLE_' +
                                getBusinessRole(user)|translate) : '*'}}</a
                            >
                        </div>
                        <br />
                        * Dem Betrieb zugeordnete User ohne aktive Rolle
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong translate>BUSINESS_BUDGET_MANAGED</strong>
                    </td>
                    <td>
                        <i
                            class="fa"
                            [ngClass]="{'fa-check':business.budgetManaged, 'fa-times':!business.budgetManaged}"
                        ></i>
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong translate>BUSINESS_STATUS</strong>
                    </td>
                    <td>{{'STATUS_' + business.status|translate}}</td>
                </tr>
                <tr>
                    <td>
                        <strong translate>BUSINESS_CREATION_DATE</strong>
                    </td>
                    <td>{{business.creationDate | date: 'dd.MM.yy HH:mm'}}</td>
                </tr>
                <tr>
                    <td>
                        <strong translate>BUSINESS_LAST_UPDATE</strong>
                    </td>
                    <td>{{business.lastUpdate | date: 'dd.MM.yy HH:mm'}}</td>
                </tr>
                <tr *ngIf="!business.master">
                    <td>
                        <strong translate>BUSINESS_MASTER_BUSINESS</strong>
                    </td>
                    <td>
                        <a
                            *ngIf="masterBusiness"
                            routerLink="/business/{{masterBusiness.id}}"
                            >{{masterBusiness.name}}</a
                        >
                    </td>
                </tr>
                <tr *ngIf="business.master">
                    <td>
                        <strong translate>BUSINESS_BUSINESSES</strong>
                    </td>
                    <td>
                        <div *ngFor="let child of childBusinesses">
                            <a routerLink="/business/{{child.id}}"
                                >{{child.name}}</a
                            >
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong translate>BUSINESS_SUPPLIERS</strong>
                    </td>
                    <td>
                        <div *ngFor="let link of business.partners">
                            {{link.supplier.name}} {{'SUPPLIER_STATUS_' +
                            link.status|translate}}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>Geschäftseinheit</strong>
                    </td>
                    <td>{{business.businessUnit}}</td>
                </tr>
            </tbody>
        </table>
        <p>
            <a (click)="showRaw = !showRaw" class="btn btn-default" translate
                >BUSINESS_SHOW_RAW</a
            >
        </p>
        <pre *ngIf="showRaw">{{business|json}}</pre>
        <p>
            <a (click)="goBack()" class="btn btn-primary" translate
                >BUTTON_BACK</a
            >
            <a
                *ngIf="enableCrmUpdate"
                (click)="crmUpdate()"
                class="btn btn-danger pull-right"
                translate
                >BUSINESS_CRM_UPDATE_BUTTON</a
            >
        </p>
    </div>
</div>
