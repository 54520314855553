<header
    class="empty"
    style="background-image: url(./frontend/img/header-bg.jpg)"
></header>
<div class="container">
    <div class="box">
        <h1>
            Vereinbarung bearbeiten
            <a routerLink="/legal-agreements" class="btn btn-default pull-right"
                >Zurück</a
            >
        </h1>
        <section>
            <form
                class="form-horizontal"
                *ngIf="form"
                (ngSubmit)="submitForm()"
                [formGroup]="form"
                [ngClass]="{loading:form?.loading}"
            >
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form-group">
                            <div class="col-sm-12">
                                <label>ID</label>
                                <div class="form-control-static">
                                    {{agreement?.id}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <input-control
                            name="title"
                            label="Titel *"
                            [form]="form"
                            type="text"
                            placeholder="Titel der Vereinbarung"
                            [showLabel]="true"
                        ></input-control>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <textarea-control
                            name="content"
                            label="Inhalt *"
                            [form]="form"
                            type="text"
                            placeholder="Inhalt der Vereinbarung"
                            [showLabel]="true"
                        ></textarea-control>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <input-control
                            name="confirmText"
                            label="Bestätigungstext *"
                            [form]="form"
                            type="text"
                            [showLabel]="true"
                        ></input-control>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <input-control
                            name="declineText"
                            label="Ablehnungstext *"
                            [form]="form"
                            type="text"
                            [showLabel]="true"
                        ></input-control>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2">
                        <input-control
                            name="validFromDate"
                            label="Gültig ab *"
                            [form]="form"
                            type="date"
                            [showLabel]="true"
                        ></input-control>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <input-control
                            name="active"
                            label="Aktiv"
                            [form]="form"
                            type="checkbox"
                        ></input-control>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <input-control
                            name="mandatory"
                            label="Zustimmungspflichtig"
                            [form]="form"
                            type="checkbox"
                        ></input-control>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <input-control
                            name="autoConfirmNewUsers"
                            label="Automatisch bei Neuregistrierung akzeptieren"
                            [form]="form"
                            type="checkbox"
                        ></input-control>
                    </div>
                </div>
            </form>
        </section>
        <section>
            <form-error [error]="formError"></form-error>
            <button
                type="submit"
                (click)="submitForm()"
                class="btn btn-primary"
            >
                Speichern
            </button>
            <a routerLink="/legal-agreements" class="btn btn-default"
                >Abbrechen</a
            >
            <a (click)="openDelete = true" class="btn btn-danger">Löschen</a>
            <div class="pull-right">* Pflichtfeld</div>
        </section>
    </div>
</div>
<ng-container *ngIf="openDelete">
    <div class="modal modal-show" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button
                        type="button"
                        class="close"
                        (click)="openDelete = false"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 class="modal-title">Löschen bestätigen</h4>
                </div>
                <div class="modal-body">
                    <p>Wollen Sie diese Vereinbarung wirklich löschen?</p>
                </div>
                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-default"
                        (click)="openDelete = false"
                    >
                        Nein
                    </button>
                    <button
                        type="button"
                        class="btn btn-primary"
                        (click)="doDelete()"
                    >
                        Ja
                    </button>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
    <div class="modal-backdrop in"></div>
</ng-container>
