<header
    class="empty"
    style="background-image: url(./frontend/img/header-bg.jpg)"
></header>
<div class="container">
    <div class="box">
        <h1>Betriebe verwalten</h1>
        <section>
            <form
                (submit)="doSearch()"
                (reset)="doSearch()"
                class="form-inline"
            >
                <div class="form-group">
                    <div class="input-group">
                        <input
                            type="search"
                            name="search"
                            [(ngModel)]="query"
                            class="form-control"
                        />
                        <span class="input-group-addon">
                            <label for="masterOnly" class="checkbox">
                                <input
                                    [(ngModel)]="masterOnly"
                                    id="masterOnly"
                                    name="masterOnly"
                                    type="checkbox"
                                />
                                <i class="fa fa-check-square"></i>
                                <i class="fa fa-square"></i>
                                {{'BUSINESS_MASTER_ONLY'|translate}}</label
                            >
                        </span>
                        <div *ngIf="enableBusinessUnit" class="input-group-btn">
                            <select
                                name="businessUnitFilter"
                                [(ngModel)]="businessUnitFilter"
                                class="form-control"
                            >
                                <option [ngValue]="null">GE filtern...</option>
                                <option
                                    *ngFor="let unit of businessUnits | keyvalue"
                                    [ngValue]="unit.value"
                                >
                                    {{unit.value}}
                                </option>
                            </select>
                        </div>
                        <div class="input-group-btn">
                            <button class="btn btn-default" type="submit">
                                <i class="fa fa-search"></i>
                            </button>
                            <a class="btn btn-default" (click)="clearSearch()">
                                <i class="fa fa-times"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </form>
        </section>
        <section>
            <nav aria-label="Page navigation">
                <ul class="pagination">
                    <li *ngIf="page > 1">
                        <a
                            [routerLink]="pageLink(page - 1)"
                            aria-label="Previous"
                        >
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>
                    <li>
                        <a>{{page}}</a>
                    </li>
                    <li>
                        <a
                            *ngIf="businesses.length >= LIMIT"
                            [routerLink]="pageLink(page + 1)"
                            aria-label="Next"
                        >
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>
            </nav>
            <table class="table">
                <thead *ngIf="businesses && businesses.length > 0">
                    <tr>
                        <th (click)="sortList('name')">Name</th>
                        <th *ngIf="enableBusinessUnit">GE</th>
                        <th (click)="sortList('creationDate')">Erstellt</th>
                        <th (click)="sortList('status')">Status</th>
                        <th (click)="sortList('master')">Master</th>
                        <th></th>
                    </tr>
                </thead>
                <thead *ngIf="!businesses || businesses.length === 0">
                    <tr>
                        <th>Name</th>
                        <th *ngIf="enableBusinessUnit">GE</th>
                        <th>Erstellt</th>
                        <th>Status</th>
                        <th>Master</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let business of businesses">
                        <td>{{business.name}}</td>
                        <td *ngIf="enableBusinessUnit">
                            {{business.businessUnit}}
                        </td>
                        <td>{{business.creationDate|date:'dd.MM.yy HH:mm'}}</td>
                        <td>{{'STATUS_' + business.status|translate}}</td>
                        <td>
                            <i
                                class="fa"
                                [ngClass]="{'fa-check':business.master,'fa-times':!business.master}"
                            ></i>
                        </td>
                        <td>
                            <a
                                routerLink="/business/{{business.id}}"
                                class="btn btn-default btn-sm"
                                >Details</a
                            >
                        </td>
                    </tr>
                </tbody>
            </table>
            <nav aria-label="Page navigation">
                <ul class="pagination">
                    <li *ngIf="page > 1">
                        <a
                            [routerLink]="pageLink(page - 1)"
                            aria-label="Previous"
                        >
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>
                    <li>
                        <a>{{page}}</a>
                    </li>
                    <li>
                        <a
                            *ngIf="businesses.length >= LIMIT"
                            [routerLink]="pageLink(page + 1)"
                            aria-label="Next"
                        >
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </section>
    </div>
</div>
