<header
    class="empty"
    style="background-image: url(./frontend/img/header-bg.jpg)"
></header>
<div class="container">
    <form
        class="box form-regular form-horizontal login-form"
        [ngClass]="{'loading':!ready || loginForm?.loading}"
        [formGroup]="loginForm"
        (ngSubmit)="submitForm()"
    >
        <h1>Anmeldung</h1>
        <section>
            <div class="row">
                <div class="col-sm-6">
                    <input-control
                        name="username"
                        [label]="'Benutzername / E-Mail'"
                        [form]="loginForm"
                        type="text"
                        [messages]="{'invalid':'Benutzername / Passwort nicht bekannt.'}"
                    ></input-control>
                </div>
                <div class="col-sm-6">
                    <input-control
                        name="password"
                        label="Passwort"
                        [form]="loginForm"
                        type="password"
                    ></input-control>
                </div>
            </div>
        </section>
        <section>
            <button type="submit" class="btn btn-xl btn-primary">
                Anmelden
            </button>
        </section>
    </form>
</div>
