import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    UserService,
    SessionService as UserwareSessionService,
    SystemRole,
    UserwareUser,
    UserwareSession,
} from '../../../client-user/index';
import { CookieService } from './cookie.service';

const SESSION_KEY = 'gstv-session';

@Injectable()
export class SessionService {
    private sessionId: string;
    private currentUser: UserwareUser;
    private ready: boolean = false;
    private readyFunctions: Function[] = [];
    private changeFunctions: Function[] = [];

    constructor(
        private sessionService: UserwareSessionService,
        private userService: UserService,
        private cookieService: CookieService,
        private router: Router,
    ) {
        let sessionId = this.cookieService.getCookie(SESSION_KEY);
        if (sessionId) {
            const handleError = (_error: any) => {
                this.setReady();
                this.cookieService.removeCookie(SESSION_KEY);
            };
            sessionService.getCurrentUserwareSession(sessionId).subscribe({
                next: ({ userId }) => {
                    userService.getUserwareUser(userId, sessionId).subscribe({
                        next: (user) => {
                            this.setSessionDetails(user, sessionId);
                            this.setReady();
                        },
                        error: (err) => handleError(err),
                    });
                },
                error: (err) => handleError(err),
            });
        } else {
            this.setReady();
        }
    }

    private setReady() {
        this.ready = true;
        this.readyFunctions.forEach((f) => {
            f();
        });
        this.readyFunctions.splice(0, this.readyFunctions.length);
        window.addEventListener('focus', () => {
            this.checkSession();
        });
    }

    private triggerChange() {
        this.changeFunctions.forEach((f) => {
            f();
        });
    }

    public setSession(userSession: UserwareSession, user: UserwareUser) {
        this.setSessionDetails(user, userSession.sessionId);
        this.triggerChange();
    }

    private setSessionDetails(user: UserwareUser, sessionId: string) {
        this.currentUser = user;
        this.sessionId = sessionId;
        this.cookieService.setCookie(SESSION_KEY, this.sessionId, 1);
    }

    public setUser(user: UserwareUser) {
        this.currentUser = user;
    }

    public getUser(): UserwareUser {
        return this.currentUser;
    }

    public isLoggedIn(): Boolean {
        return !!this.currentUser;
    }

    public getSessionId(): string {
        return this.sessionId;
    }

    public clear() {
        this.currentUser = null;
        this.sessionId = null;
        this.cookieService.removeCookie(SESSION_KEY);
        this.triggerChange();
    }

    public hasRoleName(role: string) {
        //@ts-ignore
        let r: any = SystemRole[role];
        return this.hasRole(r);
    }

    public hasRole(role: SystemRole | string): boolean {
        if (this.currentUser) {
            for (var i = 0; i < this.currentUser.systemRoles.length; i++) {
                var element = this.currentUser.systemRoles[i];
                if (element == role) {
                    return true;
                } else if (element == SystemRole.SYSTEM_ADMIN) {
                    return true;
                } else if (element == SystemRole.USER_ADMIN && role == SystemRole.BUSINESS_UNIT_USER_ADMIN) {
                    return true;
                }
            }
        }
        return false;
    }

    public checkSession() {
        if (this.sessionId) {
            const handeError = (r: Response) => {
                if (r instanceof Response) {
                    var res: Response = r;
                    if (r.status == 403) {
                        this.clear();
                        this.router.navigate(['/anmeldung']);
                    }
                }
            };
            this.sessionService.getCurrentUserwareSession(this.sessionId).subscribe({
                next: ({ userId }) => {
                    this.userService.getUserwareUser(userId, this.sessionId).subscribe({
                        next: (user) => {
                            this.setUser(user);
                        },
                        error: (err) => handeError(err),
                    });
                },
                error: (err) => handeError(err),
            });
        }
    }

    public onReady(func: Function) {
        if (this.ready) {
            func();
        } else {
            this.readyFunctions.push(func);
        }
    }

    public onLoggedIn(func: Function) {
        this.onReady(() => {
            if (this.currentUser) {
                func();
            } else {
                this.router.navigate(['/anmeldung']);
            }
        });
    }

    public onHasRole(role: SystemRole, func: Function) {
        this.onReady(() => {
            if (this.hasRole(role)) {
                func();
            } else {
                this.router.navigate(['/anmeldung']);
            }
        });
    }

    public onChange(func: Function) {
        this.changeFunctions.push(func);
    }
}
