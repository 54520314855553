<div class="row form-group" [formGroup]="form" [ngClass]="{'has-error':form.sent && form.controls[name]?.errors}" *ngIf="field">
    <div class="col-sm-12">
        <label for="{{name}}" *ngIf="showLabel">{{label}}</label>
        <div class="row" *ngFor="let o of options">
            <div class="col-sm-6">
                <div class="checkbox">
                    <label>
                    <input type="checkbox" [disabled]="disabled" [ngModel]="values?.indexOf(o.value) > -1" [ngModelOptions]="{standalone: true}" (ngModelChange)="toggle(o.value)" [placeholder]="label" class="form-control" value="true">
                        <i class="fa fa-square-o"></i><i class="fa fa-check-square"></i>{{o.label}}<ng-content></ng-content>
                    </label>
                </div>
            </div>
        </div>
        <error-block *ngIf="form.sent" [control]="form.controls[name]" [messages]="messages" [ignore]="ignore"></error-block>
        <p class="help-block" *ngIf="helpText">{{helpText}}</p>
    </div>
</div>