import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, ValidatorFn, Validators, AbstractControl } from '@angular/forms';
import { WeFormGroup } from '../../models';
import * as moment from "moment";

@Component({
    selector: 'select-check-control',
    templateUrl: 'select-check-control.html'
})
export class SelectCheckControlComponent implements OnChanges {

    @Input('form')
    form: WeFormGroup;
    @Input('name')
    name: string;
    @Input('label')
    public label: string;
    @Input('helpText')
    helpText: string;
    @Input("messages")
    messages: { [key: string]: string };
    @Input("ignore")
    ignore: string[] = [];
    @Input("showLabel")
    showLabel: boolean = false;
    @Input("disabled")
    disabled: boolean = false;
    @Input('options')
    public options: { 'name': string, 'value': string }[];

    field: AbstractControl;
    values: any[] = [];

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['form']) {
            let chng = changes['form'];
            let form: WeFormGroup = changes['form'].currentValue;
            this.field = form.controls[this.name];
            if (this.field) {
                this.values = this.field.value;
            } else {
                this.values = [];
            }
        }
    }

    toggle(option: any) {
        let i = this.values.indexOf(option);
        if (i > -1) {
            this.values.splice(i, 1);
        } else {
            this.values.push(option);
        }
    }

}
