<header
    class="empty"
    style="background-image: url(./frontend/img/header-bg.jpg)"
></header>
<div class="container" [ngClass]="{'loading':!token}">
    <div *ngIf="token" class="box">
        <h1>Anmelden als {{token?.user.name}}</h1>
        <div class="alert alert-warning">
            Wenn du dich mit diesem Link anmeldest, wird der Benutzer
            "{{token?.user.name}}" abgemeldet. Du kannst diesen Link nur einmal
            verwenden.
        </div>
        <div
            class="alert alert-danger"
            *ngIf="sessionInfos && sessionInfos.length"
        >
            Es liegt eine aktive Session für diesen Benutzer vor. Der Benutzer
            wird aber trotzdem nicht abgemeldet.
            <table *ngFor="let sessionInfo of sessionInfos ">
                <tr>
                    <td>Letzte Aktivität:</td>
                    <td>&nbsp;</td>
                    <td>{{sessionInfo.lastUpdate|date:'dd.MM.yy HH:mm'}}</td>
                </tr>
                <tr>
                    <td>Session läuft ab:</td>
                    <td>&nbsp;</td>
                    <td>{{sessionInfo.expires|date:'dd.MM.yy HH:mm'}}</td>
                </tr>
            </table>
        </div>
        <p>
            <a [href]="safeLink" target="_blank">{{link}}</a>
        </p>
        <p>
            <b>Code für App:</b>
            <input
                class="form-control"
                type="text"
                placeholder=""
                [value]="token.code"
                readonly
            />
        </p>
    </div>
</div>
