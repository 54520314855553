import { Component, OnInit } from '@angular/core';
import { SessionService } from "../services/session.service";
import { AdminService, LegalAgreement, SystemRole } from '../../../client-user/index';
import { ErrorService } from '../services/error-handle.service';
import { FlashService } from "../flash/flash.service";

@Component({

    selector: 'legal-agreements',
    templateUrl: 'legal-agreements.html'
})

export class LegalAgreementsComponent implements OnInit {

    public legalAgreements: LegalAgreement[];

    constructor(public sessionService: SessionService, private adminService: AdminService, private errorService: ErrorService, private flashService: FlashService) {
        sessionService.onHasRole(SystemRole.CONTENT_ADMIN, () => {
            this.listLegalAgreements();
        })
    }

    listLegalAgreements() {
        this.adminService.adminListLegalAgreements(this.sessionService.getSessionId()).subscribe(list => {
            this.legalAgreements = list;
            console.log(this.legalAgreements);
        }, this.errorService.buildSimpleHandler({
            statusHandles: {
                default500: (er: any) => {
                    this.flashService.addMessage("Es ist ein interner Fehler aufgetretten. " + er, "danger");
                }
            }
        }))
    }

    ngOnInit() { }
}