import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionService } from "../services/session.service";
import { SystemRole } from '../../../client-user/index';
import { DefinitionsService, SolutionFlowDefinition, SolutionFieldType, FieldConstraintType } from '../../../client-solution/index';
import { ErrorService, FormErrorValue } from '../services/error-handle.service';
import { FlashService } from "../flash/flash.service";

@Component({
    selector: 'definition-detail',
    templateUrl: 'solution-detail.html'
})

export class SolutionDetailComponent implements OnInit {
    definition: SolutionFlowDefinition;
    formError: FormErrorValue = new FormErrorValue();
    openDelete: boolean = false;
    loading: boolean = false;
    fieldTypes: { label: string, value: SolutionFieldType }[] =
        [{
            label: "Text", value: SolutionFieldType.STRING
        }, {
            label: "Langer Text", value: SolutionFieldType.LONG_STRING
        }, {
            label: "Zahl", value: SolutionFieldType.NUMBER
        }, {
            label: "Bool", value: SolutionFieldType.BOOLEAN
        }, {
            label: "Datum", value: SolutionFieldType.DATE
        }, {
            label: "Auswahl", value: SolutionFieldType.CHOICE
        }
        ]

    constructor(public sessionService: SessionService, private definitionsService: DefinitionsService, private route: ActivatedRoute, private router: Router, private errorService: ErrorService, private flashService: FlashService) {

        sessionService.onHasRole(SystemRole.CONTENT_ADMIN, () => {
            this.route.params.subscribe((params: any) => {
                let id = params['id'];
                if (id) {
                    this.definitionsService.getFlow(id).subscribe(c => {
                        this.definition = c;
                    }, this.errorService.buildSimpleHandler({
                        statusHandles: {
                            default: (code: any) => {
                                this.router.navigate(['/error/' + code])
                            }
                        }
                    }));
                } else {
                    this.definition = {
                        id: '',
                        lastUpdate: undefined,
                        creationDate: undefined,
                        name: '',
                        label: '',
                        active: false,
                        partner: { 
                            id: '',
                            lastUpdate: undefined,
                            creationDate: undefined,
                            name: '',
                            termsUrl: '',
                            deliveryZipCodes: [],
                        },
                        fieldDefinitions: [],
                    }
                }
            })
        })
    }


    public removeField(i: number) {
        if (this.definition) {
            this.definition.fieldDefinitions.splice(i, 1);
        }
    }

    public addField() {
        if (this.definition) {
            if (!this.definition.fieldDefinitions) {
                this.definition.fieldDefinitions = [];
            }
            this.definition.fieldDefinitions.push({ label: "", name: "", fieldType: SolutionFieldType.STRING, column: 1 });
        }
    }

    public constraintIs(index: number, type: string): boolean {
        if (this.definition && this.definition.fieldDefinitions && this.definition.fieldDefinitions[index] && this.definition.fieldDefinitions[index].validations) {
            for (var i = 0; i < this.definition.fieldDefinitions[index].validations.length; i++) {
                if (this.definition.fieldDefinitions[index].validations[i].type == type) {
                    return true;
                }
            }
        }
        return false;
    }

    public setConstraint(value: any, index: number, type: string) {
        //@ts-ignore
        if (this.definition && this.definition.fieldDefinitions && this.definition.fieldDefinitions[index]) {
            if (!this.definition.fieldDefinitions[index].validations) {
                this.definition.fieldDefinitions[index].validations = [];
            }
            if (value) {
                this.definition.fieldDefinitions[index].validations.push({ type: type as FieldConstraintType })
            } else {
                let pos = this.definition.fieldDefinitions[index].validations.findIndex((cur, z, obj) => {
                    return cur.type == type;
                });
                if (pos > -1) {
                    this.definition.fieldDefinitions[index].validations.splice(pos, 1);
                }
            }
        }
    }

    public submitForm() {
        this.loading = true;
        this.definitionsService.updateFieldDefinitions(this.definition.id, this.sessionService.getSessionId(), this.definition.fieldDefinitions).subscribe((newFlow) => {
            this.flashService.addMessage("Änderungen wurden gespeichert!", "success");
            this.loading = false;
        }, this.errorService.buildSimpleHandler({
            always: () => {
                this.loading = false;
            }
        }));
    }


    ngOnInit() { }


}