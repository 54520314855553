import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionService } from "../services/session.service";
import { User, Business, AdminService, SystemRole } from '../../../client-user/index';
import { ErrorService } from '../services/error-handle.service';
import { FlashService } from "../flash/flash.service";
@Component({
  selector: 'business-detail',
  templateUrl: 'business-detail.html'
})

export class BusinessDetailComponent implements OnInit {
  business: Business;
  masterBusiness: Business;
  childBusinesses: Business[] = [];
  users: User[] = [];
  loading: boolean = true;
  enableCrmUpdate: boolean = false;

  errorHandler = this.errorService.buildSimpleHandler({
    statusHandles: {
      default: (code: any) => {
        this.router.navigate(['/error/' + code])
      }
    }
  });

  constructor(public sessionService: SessionService, private adminService: AdminService, private route: ActivatedRoute, private router: Router, private errorService: ErrorService, private flashService: FlashService) {

    sessionService.onHasRole(SystemRole.BUSINESS_UNIT_USER_ADMIN, () => {
      this.enableCrmUpdate = sessionService.hasRole(SystemRole.USER_ADMIN)
      this.route.params.subscribe(params => {
        let id = params['id'];
        if (id) {
          this.adminService.adminGetBusinessById(id, this.sessionService.getSessionId()).subscribe((c) => {
            this.business = c;
            if (!this.business.master) {
              this.adminService.adminGetMasterBusiness(this.business.customerId, this.sessionService.getSessionId()).subscribe(b => {
                this.masterBusiness = b;
                this.loading = false;
              }, this.errorHandler)
            } else {
              this.adminService.adminListBusinesses(this.sessionService.getSessionId(), "", this.business.customerId).subscribe(children => {
                this.childBusinesses = [];
                this.loading = false;
                children.forEach(child => {
                  if (child.id != this.business.id) {
                    this.childBusinesses.push(child);
                  }
                });

              })
            }
            this.adminService.adminListUsers(this.sessionService.getSessionId(), null, null, id, null, null, true).subscribe((users) => {
              this.users = users;
            })
          }, this.errorHandler);
        } else {
          this.router.navigate(['/businesss/']);
        }

      })
    })
  }

  ngOnInit() { }

  goBack() {
    window.history.back();
  }

  getBusinessRole(user: User): string {
    for (let i = 0; i < user.businessRoles.length; i++) {
      const element = user.businessRoles[i];
      if (element.business.id == this.business.id) {
        return element.role;
      }
    }
  }

  crmUpdate() {
    this.loading = true;
    this.adminService.adminCRMFullUpdateBusiness(this.business.id, this.sessionService.getSessionId()).subscribe((business) => {
      this.business = business;
      this.flashService.addMessage('BUSINESS_UPDATE_SUCCESS', 'success');
      this.loading = false;
    }, this.errorHandler)
  }

}