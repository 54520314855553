import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { SessionService } from '../services/session.service'
import { Supplier, AdminService, BusinessUnit, SystemRole } from '../../../client-user/index'
import { ErrorService } from '../services/error-handle.service'
import { FlashService } from '../flash/flash.service'
import { sortObjectArrByProps } from '@nighly/sort-object-array-by-property'

@Component({
    selector: 'suppliers',
    templateUrl: 'suppliers.html',
})
export class SuppliersComponent implements OnInit {
    lastKeyForSort: string = ''
    listIsReversed: boolean = false
    suppliers: Supplier[] = []
    query: string = ''
    businessUnits = BusinessUnit
    businessUnitFilter?: BusinessUnit
    enableBusinessUnit: boolean = false
    LIMIT = 10
    page: number = 1

    constructor(
        public sessionService: SessionService,
        private adminService: AdminService,
        private router: Router,
        private route: ActivatedRoute,
        private errorService: ErrorService,
        private flashService: FlashService
    ) {
        sessionService.onHasRole(
            SystemRole.BUSINESS_UNIT_USER_ADMIN,
            () => {
                this.enableBusinessUnit = sessionService.hasRole(
                    SystemRole.USER_ADMIN
                )
                this.route.params.subscribe((params: any) => {
                    if (params['q']) {
                        this.query = params['q']
                    }
                    let p = params['page']
                    if (p && p > 0) {
                        this.page = parseInt(p)
                    }
                    this.businessUnitFilter =
                        //@ts-ignore
                        BusinessUnit[params['businessUnit']] || null
                    this.getSuppliers()
                })
            }
        )
    }

    doSearch() {
        this.page = 1
        let d: any = { page: this.page }
        if (this.query) {
            d['q'] = this.query
        }
        if (this.businessUnitFilter) {
            d['businessUnit'] = this.businessUnitFilter
        }
        this.router.navigate(['suppliers', d])
    }

    clearSearch() {
        this.query = ''
        this.businessUnitFilter = null
        this.page = 1
        this.router.navigate(['suppliers'])
    }

    getSuppliers() {
        let offset = (this.page - 1) * this.LIMIT
        this.adminService
            .adminListSuppliers(
                this.sessionService.getSessionId(),
                this.query,
                this.LIMIT,
                offset,
                this.businessUnitFilter
            )
            .subscribe(
                (list) => {
                    this.suppliers = list
                },
                this.errorService.buildSimpleHandler({
                    statusHandles: {
                        default500: (er: any) => {
                            this.flashService.addMessage(
                                'Es ist ein interner Fehler aufgetretten. ' +
                                    er,
                                'danger'
                            )
                        },
                    },
                })
            )
    }

    pageLink(page: number) {
        return [
            '/suppliers',
            {
                q: this.query,
                businessUnit: this.businessUnitFilter,
                page,
            },
        ]
    }

    sortList(paramKey: string) {
        let response: Supplier[] = []
        if (this.lastKeyForSort === paramKey && !this.listIsReversed) {
            response = sortObjectArrByProps(this.suppliers, paramKey, 'r')
            this.listIsReversed = true
        } else {
            response = sortObjectArrByProps(this.suppliers, paramKey)
            this.listIsReversed = false
        }
        this.lastKeyForSort = paramKey
        this.suppliers = response
    }

    ngOnInit() {}
}
