import { ModuleWithProviders } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

import { UserLoginComponent } from './user-login/user-login.component'
import { UserLogoutComponent } from './user-logout/user-logout.component'
import { UsersComponent } from './users/users.component'
import { UserDetailComponent } from './user-detail/user-detail.component'
import { UserRolesComponent } from './user-roles/user-roles.component'
import { BusinesssComponent } from './businesses/businesses.component'
import { BusinessDetailComponent } from './business-detail/business-detail.component'
import { SolutionsComponent } from './solutions/solutions.component'
import { SolutionDetailComponent } from './solution-detail/solution-detail.component'
import { CampaignsComponent } from './campaigns/campaigns.component'
import { CampaignDetailComponent } from './campaign-detail/campaign-detail.component'
import { AdPackagesComponent } from './ad-packages/ad-packages.component'
import { AdPackageDetailComponent } from './ad-package-detail/ad-package-detail.component'
import { LegalAgreementsComponent } from './legal-agreements/legal-agreements.component'
import { LegalAgreementDetailComponent } from './legal-agreement-detail/legal-agreement-detail.component'
import { StartComponent } from './start/start.component'
import { ErrorsComponent } from './errors/errors.component'
import { TokenLoginComponent } from './token-login/token-login.component'
import { SuppliersComponent } from './suppliers/suppliers.component'
import { SupplierDetailComponent } from './supplier-detail/supplier-detail.component'
import { HomepageSettings } from './homepage-settings/homepage-settings'

const routes: Routes = [
    {
        path: 'anmeldung',
        component: UserLoginComponent,
    },
    {
        path: 'abmelden',
        component: UserLogoutComponent,
    },
    {
        path: 'users',
        component: UsersComponent,
    },
    {
        path: 'user/:id',
        component: UserDetailComponent,
    },
    {
        path: 'token-login/:id',
        component: TokenLoginComponent,
    },
    {
        path: 'user-roles/:id',
        component: UserRolesComponent,
    },
    {
        path: 'businesses',
        component: BusinesssComponent,
    },
    {
        path: 'business/:id',
        component: BusinessDetailComponent,
    },
    {
        path: 'suppliers',
        component: SuppliersComponent,
    },
    {
        path: 'supplier/:id',
        component: SupplierDetailComponent,
    },
    {
        path: 'solutions',
        component: SolutionsComponent,
    },
    {
        path: 'solution/create',
        component: SolutionDetailComponent,
    },
    {
        path: 'solution/:id',
        component: SolutionDetailComponent,
    },
    {
        path: 'campaign',
        component: CampaignsComponent,
    },
    {
        path: 'campaign/create',
        component: CampaignDetailComponent,
    },
    {
        path: 'campaign/:id',
        component: CampaignDetailComponent,
    },
    {
        path: 'start',
        component: StartComponent,
    },
    {
        path: 'ad-packages',
        component: AdPackagesComponent,
    },
    {
        path: 'ad-package/:id',
        component: AdPackageDetailComponent,
    },
    {
        path: 'legal-agreements',
        component: LegalAgreementsComponent,
    },
    {
        path: 'legal-agreement/create',
        component: LegalAgreementDetailComponent,
    },
    {
        path: 'legal-agreement/:id',
        component: LegalAgreementDetailComponent,
    },
    {
        path: 'error/:code',
        component: ErrorsComponent,
    },
    {
        path: 'homepage',
        component: HomepageSettings,
    },
    {
        path: '**',
        redirectTo: 'start',
        pathMatch: 'full',
    },
]

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes)

export const routedComponents = [UserLoginComponent, UserLogoutComponent]
