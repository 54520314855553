<header
    class="empty"
    style="background-image: url(./frontend/img/header-bg.jpg)"
></header>
<div class="container">
    <div class="box">
        <h1>Lösungen verwalten</h1>
        <section>
            <table class="table table-striped">
                <thead *ngIf="definitions && definitions.length > 0">
                    d>
                    <tr>
                        <th (click)="sortList('id')">ID</th>
                        <th (click)="sortList('name')">Name</th>
                        <th (click)="sortList('partner?.name')">Partnername</th>
                        <th (click)="sortList('creationDate')">Erstellt</th>
                        <th (click)="sortList('lastUpdate')">
                            Letzte Änderung
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <thead *ngIf="!definitions || definitions.length === 0">
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Partnername</th>
                        <th>Erstellt</th>
                        <th>Letzte Änderung</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let d of definitions">
                        <td>{{d.id}}</td>
                        <td>{{d.name}}</td>
                        <td>{{d.partner?.name}}</td>
                        <td>{{d.creationDate|date:'dd.MM.yy HH:mm'}}</td>
                        <td>{{d.lastUpdate|date:'dd.MM.yy HH:mm'}}</td>
                        <td>
                            <a
                                routerLink="/solution/{{d.id}}"
                                class="btn btn-primary btn-xs"
                                >Bearbeiten</a
                            >
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="alert alert-info" *ngIf="!definitions?.length">
                Es gibt zur Zeit keine Lösungen die der Suche entsprechen.
            </div>
        </section>
        <section>
            <a routerLink="/solution/create" class="btn btn-primary"
                >Neue Lösung erstellen</a
            >
        </section>
    </div>
</div>
