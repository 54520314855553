/**
 * Contentware
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: .
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AdType = 'CONTENT_TOP_BANNER' | 'CONTENT_LEVEL_1' | 'CONTENT_LEVEL_2' | 'CONTENT_LEVEL_3' | 'BANNER_START_LEVEL_1' | 'BANNER_START_LEVEL_2' | 'BANNER_START_LEVEL_3' | 'BANNER_START_LEVEL_4' | 'BANNER_ARTICLE_MID' | 'BANNER_ARTICLE_BOTTOM' | 'BANNER_ARTICLE_RIGHT' | 'BANNER_FIXED_BOTTOM' | 'PREVIEW' | 'SOLUTION' | 'BANNER_SOLUTIONS_LEVEL_1' | 'BANNER_SOLUTIONS_LEVEL_2';

export const AdType = {
    CONTENT_TOP_BANNER: 'CONTENT_TOP_BANNER' as AdType,
    CONTENT_LEVEL_1: 'CONTENT_LEVEL_1' as AdType,
    CONTENT_LEVEL_2: 'CONTENT_LEVEL_2' as AdType,
    CONTENT_LEVEL_3: 'CONTENT_LEVEL_3' as AdType,
    BANNER_START_LEVEL_1: 'BANNER_START_LEVEL_1' as AdType,
    BANNER_START_LEVEL_2: 'BANNER_START_LEVEL_2' as AdType,
    BANNER_START_LEVEL_3: 'BANNER_START_LEVEL_3' as AdType,
    BANNER_START_LEVEL_4: 'BANNER_START_LEVEL_4' as AdType,
    BANNER_ARTICLE_MID: 'BANNER_ARTICLE_MID' as AdType,
    BANNER_ARTICLE_BOTTOM: 'BANNER_ARTICLE_BOTTOM' as AdType,
    BANNER_ARTICLE_RIGHT: 'BANNER_ARTICLE_RIGHT' as AdType,
    BANNER_FIXED_BOTTOM: 'BANNER_FIXED_BOTTOM' as AdType,
    PREVIEW: 'PREVIEW' as AdType,
    SOLUTION: 'SOLUTION' as AdType,
    BANNER_SOLUTIONS_LEVEL_1: 'BANNER_SOLUTIONS_LEVEL_1' as AdType,
    BANNER_SOLUTIONS_LEVEL_2: 'BANNER_SOLUTIONS_LEVEL_2' as AdType
};

